import Vue from "vue";
import VueRouter from "vue-router";
// 引入路由
import HomeView from "@/views/HomeView.vue";
import Huijun from "@/views/HuiJun/HuiJun.vue";
import Solution from "@/views/Solution/Solution.vue";
import TechnologyProducts from "@/views/TechnologyProducts/TechnologyProducts.vue";
import ProductsDetail from "@/views/TechnologyProducts/ProductsDetail"
import Case from "@/views/Case/Case.vue";
import News from "@/views/News/News.vue";
import NewsDetail from "@/views/News/NewsDetail.vue";
import JoinUs from "@/views/Us/JoinUs.vue";
import CallUs from "@/views/Us/CallUs.vue";

const originalPush = VueRouter.prototype.push
// 修改 原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err=>err)
}

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/huijun",
    name: "走进汇君",
    component: Huijun,
  },
  {
    path: "/solution",
    name: "解决方案",
    component: Solution,
  },
  {
    path: "/technologyproducts",
    name: "技术与产品",
    component: TechnologyProducts,
  },
  {
    path: "/productsDetail",
    name: "产品详情",
    component: ProductsDetail,
  },
  {
    path: "/case",
    name: "典型案列",
    component: Case,
  },
  {
    path: "/news",
    name: "新闻中心",
    component: News,
  },
  {
    path: "/newsDetail",
    name: "新闻中心",
    component: NewsDetail,
  },
  {
    path: "/joinUs",
    name: "加入我们",
    component: JoinUs,
  },
  {
    path: "/callUs",
    name: "联系方式",
    component: CallUs,
  },
  {
    path: "*",
    redirect: "/",
  },
];

const router = new VueRouter({
  routes,
});

export default router;
