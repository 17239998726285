<template>
  <div>
    <div class="main">
      <div class="sign" ref="sign"></div>
      <div class="main-bg"></div>
      <div class="main-text">
        <p class="text-title" style="margin: 0.417rem 0">新闻中心</p>
        <p class="text-title-en">NEWS CENTER</p>
      </div>
    </div>
    <!-- pc端 -->
    <div class="nav-head" v-if="!phoneSize">
      <div class="nav-title">
        <el-menu
          :default-active="activeIndex"
          class="el-menu-demo"
          mode="horizontal"
          text-color="#fff"
          background-color="transparent"
          active-text-color="#fff"
        >
          <el-menu-item
            :index="item.id"
            v-for="item in cateList"
            :key="item.id"
            @click="changeCate(item.id)"
          >
            {{ item.title }}
          </el-menu-item>
        </el-menu>
      </div>
      <div class="nav-text">
        <div style="margin-right: 0.625rem; width: 1.042rem; height: 1.042rem">
          <img src="@/assets/images/icon/icon-house.png" alt="" />
        </div>
        <div class="right-nav">
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>新闻中心</el-breadcrumb-item>
            <el-breadcrumb-item v-if="cateId == 1">公司新闻</el-breadcrumb-item>
            <el-breadcrumb-item v-else>行业状态</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
      </div>
    </div>
    <!-- 手机端 -->
    <div class="nav-two" v-if="phoneSize">
      <div
        class="two-wrap"
        v-for="item in cateList"
        :key="item.id"
        @click="changeCate(item.id)"
      >
        <div class="two-text">{{ item.title }}</div>
      </div>
    </div>
    <!-- PC端新闻 -->
    <div class="news" v-if="!phoneSize">
      <div class="news-left"
      ref="detail">
        <h2>{{ newsDetail.title }}</h2>
        <div class="base">
          <div class="date">
            <div class="icon">
              <img src="@/assets/images/news/icon_1.png" alt="" align="top" />
            </div>
            {{ newsDetail.time }}
          </div>
          <div class="date">
            <div class="icon">
              <img src="@/assets/images/news/icon_2.png" alt="" align="top" />
            </div>
            汇君环能
          </div>
        </div>
        <div class="division">
          <div class="round green"></div>
          <div class="line green"></div>
          <div class="round green"></div>
        </div>
        <div class="news-main" v-html="newsDetail.content1"></div>
        <div class="division">
          <div class="round grey"></div>
          <div class="line grey"></div>
          <div class="round grey"></div>
        </div>
        <div class="news-foot">
          <div
            class="nextnews"
            v-if="newsDetail.next.id != 0"
            @click="toupdate(newsDetail.next.id)"
          >
          上一篇:{{ newsDetail.next.title }}
          </div>
          <div
            class="nextnews"
            v-if="newsDetail.pre.id != 0"
            @click="toupdate(newsDetail.pre.id)"
          >
          下一篇:{{ newsDetail.pre.title }}
          </div>
        </div>
      </div>
      <div  class="news-right"
      ref="news"
      :class="navFixed ?'news-fixed':''">
        <div style="font-size: 0.917rem; margin: 0.833rem 0">相关新闻</div>
        <div class="other-news">
          <div class="one-news"  @click="toupdate(item.id)" v-for="item in newsDetail.recommend" :key="item.index">
            <div class="image"><img :src="$pregImg + item.thumb" alt="" /></div>
            <div class="news-title">{{ item.title }}</div>
            <el-divider></el-divider>
            <div class="news-text">{{ item.description }}</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 手机端新闻 -->
    <div class="news-phone" v-if="phoneSize">
      <div class="news-left-phone"
      ref="detail">
        <h2>{{ newsDetail.title }}</h2>
        <div class="base">
          <div class="date">
            <div class="icon">
              <img src="@/assets/images/news/icon_1.png" alt="" align="top" />
            </div>
            {{ newsDetail.time }}
          </div>
          <div class="date">
            <div class="icon">
              <img src="@/assets/images/news/icon_2.png" alt="" align="top" />
            </div>
            汇君环能
          </div>
        </div>
        <div class="division">
          <div class="round green"></div>
          <div class="line green"></div>
          <div class="round green"></div>
        </div>
        <div class="news-main size" v-html="newsDetail.content1"></div>
        <div class="division">
          <div class="round grey"></div>
          <div class="line grey"></div>
          <div class="round grey"></div>
        </div>
        <div class="news-foot-phone">
          <div
            class="nextnews"
            v-if="newsDetail.next.id != 0"
            @click="toupdate(newsDetail.next.id)"
          >
          上一篇:{{ newsDetail.next.title }}
          </div>
          <div
            class="nextnews"
            v-if="newsDetail.pre.id != 0"
            @click="toupdate(newsDetail.pre.id)"
          >
          下一篇:{{ newsDetail.pre.title }}
          </div>
        </div>
      </div>
      <div  class="news-right-phone">
        <div style="font-size: 16px; margin: 0.833rem 0">相关新闻</div>
        <div class="other-news-phone">
          <div class="one-news-phone"  @click="toupdate(item.id)" v-for="item in newsDetail.recommend" :key="item.index">
            <div class="image"><img :src="$pregImg + item.thumb" alt="" /></div>
            <div class="news-title size">{{ item.title }}</div>
            <el-divider></el-divider>
            <div class="news-text size">{{ item.description }}</div>
          </div>
        </div>
      </div>
    </div>
    <div ref="fj" style="width:0.042rem;height: 0.042rem;"></div>
    <foot></foot>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  data () {
    return {
      activeIndex: '1',
      cateId: 1, // 标题id
      id: '', // 商品id
      cateList: [], //标题列表
      newsDetail: {}, //新闻详情
      navFixed:false,
      navHeight:0,
      height:0,
      endHeight:0,
    };
  },
  watch: {
    '$route': function () {
      this.activeIndex = this.$route.query.cateId
      this.cateId = this.$route.query.cateId
      this.queryList()
    }
  },
  created () {
    console.log(this.$route.query)
    this.id = this.$route.query.id
    this.activeIndex = this.$route.query.cateId
    this.cateId = this.$route.query.cateId
    this.queryList()
    // this.$axios.get('/api/news/category').then((res) => {
      this.$axios.get('news/category').then((res) => {
      this.cateList = res.data.data.list
      console.log(this.cateList);
    })
  },
  computed: {
    ...mapState(["phoneSize"]),
  },
  mounted () {
     window.addEventListener('scroll', this.handleTabFix, true)
  },
  updated(){
    // 元素距离页面顶部的高度
    const a = this.$refs.detail.offsetTop
    const b = this.$refs.sign.offsetTop
    const c = this.$refs.detail.offsetHeight
    const d = this.$refs.fj.offsetTop
    this.navHeight = a-b
    this.height= c;
    this.endHeight = d
  },
  
  methods: {
    changeCate (id) {
      this.$router.push({ path: 'news', query: { id: id } })
    },
    toupdate (id) {
      this.id = id
      this.queryList()
      window.scrollTo(0, 0);
    },
    // 更新工作数据
    queryList () {
      // this.$axios.get('/api/news/detail?id=' + this.id).then((res) => {
        this.$axios.get('news/detail?id=' + this.id).then((res) => {
        this.newsDetail = res.data.data
      })
    },
    // 获取页面滚动距离
    handleTabFix() {
      // const viewHeight = window.innerHeight 
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      const viewHeight = window.innerHeight || document.documentElement.innerHeight || document.body.clientHeight;
      if(scrollTop > this.navHeight && scrollTop<(this.endHeight - viewHeight)){
        this.navFixed = true
      }else{
        this.navFixed = false
      }
    },
  },
  // 离开当前组件前一定要清除滚动监听，否则进入其他路由会报错
  beforeRouteLeave (to, from, next) {
    window.removeEventListener('scroll', this.handleTabFix, true)
    next()
  },
}
</script>
<style scoped>
  .size{font-size: 14px !important;}
  .size /deep/p{
    font-size: 14px !important;
  }
.el-menu-item:hover{
  color: #fff !important;
}
.el-menu-item{
  color: rgba(255, 255, 255, 0.7) !important;
}
.text-title {
  font-size: 2.667rem;
  font-family: ssb;
}
.text-title-en {
  font-size: 1.5rem;
  font-family: Arial, Helvetica, sans-serif;
}
img {
  width: 100%;
  height: 100%;
}
/* 走进汇君 */
.main {
  width: 100%;
  padding-top: 0.042rem;
  height: 36.667rem;
  overflow: hidden;
  position: relative;
}
.sign{
  width: 0.042rem;
  height: 0.042rem;
  position: absolute;
  right: 0;
  top: 4rem;
  background: #fff;
  opacity: 0.000001;
}
.main-bg{
  position: absolute;
  width: 100%;
  height: 100%;
  background: url(@/assets/images/news/newBg_01.jpg) no-repeat center;
  background-size: cover;
  animation: zoom_big2 16s ease-out infinite;
  z-index: -1;
}
@keyframes zoom_big2 {
  0%{
    transform: scale(1);
  }
  60% {
    transform: scale(1.05);
  }
  95% {
    transform: scale(1);
  }
  100% {
    transform: scale(1);
  }
}
.main-text {
  margin: 15.417rem 0 0 3.333rem;
  color: #fff;
}

/* 分类导航 */
.nav-head {
  position: relative;
  margin-bottom: 9.167rem;
}
.nav-title {
  width: 54.167rem;
  height: 6.667rem;
  background-image: linear-gradient(to right, #72caed, #26b1e0);
  border-radius: 0 3.75rem 0 0;
  position: absolute;
  left: 0;
  top: -3.75rem;
  z-index: 11;
  padding: 0 0 0 4.167rem;
}
.nav-text {
  width: 41.667rem;
  height: 6.25rem;
  background-image: linear-gradient(to right, #e6eeca, #a6c142);
  border-radius: 0 0 0 2.083rem;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.right-nav {
  margin-right: 3.333rem;
}
/* 新闻 */
.nextnews {
  cursor: pointer;
  font-weight: 550;
  margin-bottom: 0.833rem;
}
.news {
  display: flex;
  padding:0 3.75rem;
  margin-bottom: 6rem;
}
.news-phone{
  padding: 0 3.75rem;
  margin-bottom: 6rem;
}
.news-left {
  width: 50.75rem;
  height: auto;
  margin-right: 3.625rem;
  padding: 2.083rem 1.667rem;
  border-radius: 0.417rem;
  border-top: 0.167rem solid #39b7e3;
  background: #ededed;
}
.news-left-phone{
  width: 100%;
  height: auto;
  padding: 2.083rem 1.667rem;
  border-radius: 0.417rem;
  border-top: 0.167rem solid #39b7e3;
  background: #ededed;
}
.base {
  display: flex;
  margin-top: 1.042rem;
}
.date {
  height: 1.042rem;
  font-size: 0.583rem;
  margin-right: 1.25rem;
  line-height: 1.042rem;
}
.icon {
  display: inline-block;
  width: 1.375rem;
  height: 1.042rem;
  padding-right: 0.333rem;
}
.news-main{
  width: 100%;
}
.news-main /deep/ p{
  font-size: 0.667rem;
  line-height: 2 !important;
}
.news-main /deep/ img{
  max-width: 100%;
  height: auto !important;
  margin-left: 50%;
  transform: translateX(-50%);
}
.division {
  width: 100%;
  height: 2.917rem;
  display: flex;
}
.round {
  width: 0.25rem;
  height: 0.25rem;
  border-radius: 50%;
}
.line {
  width: 48.333rem;
  height: 0.083rem;
}
.green {
  background: #a6c142;
  margin: auto 0;
}
.grey {
  background: #dddddd;
  margin: auto 0;
}
.news-foot {
  width: 100%;
  padding: 0 0.833rem;
  display: flex;
  justify-content: space-between;
  font-size: 0.583rem;
}
.news-foot-phone{
  width: 100%;
  padding: 0 0.833rem;
  display: flex;
  flex-direction: column;
  font-size: 0.583rem;
}
.news-right {
  width: 18.125rem;
}
.news-right-phone{
  width: 100%;
}
.news-fixed{
  position: fixed;
  right: 2.958rem;
  top: 4rem;
}
.other-news {
  width: 100%;
  height: 29.167rem;
  overflow-y: auto;
}
.other-news-phone {
  width: 100%;
  height: auto;
  overflow-x: auto;
  display: flex;
}
.one-news-phone{
  width: 12.5rem;
  height: auto;
  margin-right: 0.833rem;
  margin-bottom: 1.25rem;
  cursor: pointer;
}
.image {
  width: 100%;
  height: 10.208rem;
  overflow: hidden;
}
.image img {
  transition: all 0.6s ease;
}
.news-title {
  margin: 0.75rem 0.292rem;
  font-size: 0.667rem;
  font-weight: 550;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.one-news:hover img {
  transform: scale(1.05);
}
.one-news:hover .news-title {
  color: #28b2e1;
}
.news-text {
  margin: 0.75rem 0.292rem;
  font-size: 0.5rem;
  line-height: 1.5;
  color: #737373;
  white-space: nowrap; /*溢出不换行*/
  overflow: hidden; /*溢出部分隐藏*/
  text-overflow: ellipsis;
}
/* 样式覆盖 */
.other-news >>> .el-divider--horizontal {
  margin: 0;
}
.nav-head >>> .el-breadcrumb {
  font-size: 0.583rem;
}
.nav-head >>> .el-menu.el-menu--horizontal {
  height: 6.67rem;
  border-bottom: solid 0 #e6e6e6;
}
.nav-head >>> .el-menu--horizontal > .el-menu-item {
  height: 6.67rem;
  line-height: 6.67rem;
  font-size: 0.917rem;
  text-align: center;
}
.nav-head >>> .el-breadcrumb__inner.is-link {
  color: #fff;
}
.nav-head >>> .el-breadcrumb__separator {
  color: #fff;
}
.nav-head >>> .el-breadcrumb__item .el-breadcrumb__inner {
  color: #fff;
}
.nav-head >>> .el-menu .el-menu-item:after {
  content: '';
  display: block;
  width: 0.042rem;
  height: 0.833rem;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  background-color: #fff;
}
.el-menu .el-menu-item:last-child:after {
  display: none;
}
.nav-head >>> .el-menu--horizontal .el-menu-item.is-active {
  border-bottom: 0.167rem solid #aac93b !important;
}
</style>