import "./assets/css/reset.css";
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
// 引入axios
import axios from "axios";
Vue.prototype.$axios = axios;
// 引入swiper样式
import "swiper/dist/css/swiper.min.css";
import "swiper/dist/js/swiper.min";

// 引入element-ui
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
//全局公共组件
import head from "@/components/Header/head.vue";
import foot from "@/components/Footer/foot.vue";
Vue.component("top", head);
Vue.component("foot", foot);

// 全局前置守卫
router.afterEach((to, form, next) => {
  window.scrollTo(0, 0);
});
// store仓库
import store from "@/store/index";
import "./style/font-family.css";
// swiper动画
import * as animated from "animate.css"
import {
  swiperAnimateCache,
  swiperAnimate,
  clearSwiperAnimate
 } from "@/assets/js/swiper.animate1.0.3.min.js"
 
 Vue.prototype.$swiperAnimateCache = swiperAnimateCache
 Vue.prototype.$swiperAnimate = swiperAnimate
 Vue.prototype.$clearSwiperAnimate = clearSwiperAnimate
Vue.use(animated)
// Vue.prototype.$pregImg = "http://test4.xinshidian.top";
// Vue.prototype.$pregImg = "http://www.huijun.net.cn/";
Vue.prototype.$pregImg = "https://huijun.net.cn/";
Vue.use(ElementUI);
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
