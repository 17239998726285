var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footer"},[(!_vm.phoneSize)?_c('div',{staticStyle:{"display":"flex","border-bottom":"solid 0.083rem #2b586c","color":"#fff","padding-bottom":"0.417rem"}},[_vm._m(0),_vm._m(1),_vm._m(2)]):_vm._e(),(_vm.phoneSize)?_c('div',{staticStyle:{"width":"100%","border-bottom":"solid 0.083rem #2b586c","color":"#fff","padding-top":"1.5833rem","padding-bottom":"0.417rem"}},[_vm._m(3),_vm._m(4),_c('div',{staticClass:"nav-icon"},[_c('div',{ref:"icon1",staticClass:"icon-warp",on:{"click":function($event){_vm.icon1 = !_vm.icon1}}},[_c('i',{staticClass:"el-icon-mobile-phone"})]),_c('div',{ref:"icon2",staticClass:"icon-warp",on:{"click":function($event){_vm.icon2 = !_vm.icon2}}},[_c('i',{staticClass:"el-icon-folder"})]),_c('div',{ref:"icon3",staticClass:"icon-warp",on:{"click":function($event){_vm.icon3 = !_vm.icon3}}},[_c('i',{staticClass:"el-icon-map-location"})]),_c('div',{ref:"icon4",staticClass:"icon-warp",on:{"click":function($event){_vm.icon4 = !_vm.icon4}}},[_c('i',{staticClass:"el-icon-more"})]),_c('div',{staticClass:"icon-text"},[(_vm.icon1)?_c('div',{staticClass:"phone"},[_vm._v("电话:86-0532-55550126")]):_vm._e(),(_vm.icon2)?_c('div',{staticClass:"fax"},[_vm._v("传真:huijun@huijun.net.cn")]):_vm._e(),(_vm.icon3)?_c('div',{staticClass:"address"},[_vm._v(" 地址:中国·青岛市崂山区松岭路330号3号楼F11A、F11B ")]):_vm._e(),(_vm.icon4)?_c('div',{staticClass:"wx"}):_vm._e()])])]):_vm._e(),_c('div',{class:!_vm.phoneSize ? 'bot' : ''},[_c('div',{staticClass:"text",on:{"click":function($event){return _vm.toBeiAn()}}},[_vm._v(" Copyright All Rights 青岛汇君环境能源工程有限公司 鲁ICP备: 19002213号-2 "),_vm._m(5)]),_c('div',{staticClass:"text",on:{"click":function($event){return _vm.toXinShiDian()}}},[_vm._v("技术支持 | 青岛新视点网络")])]),(_vm.phoneSize)?_c('div',{staticStyle:{"width":"100%","height":"1.333rem"}}):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"foot-l"},[_c('div',{staticClass:"foot-img"},[_c('img',{attrs:{"src":require("@/assets/images/logo-d.png"),"alt":""}})]),_c('div',{staticClass:"foot-text"},[_vm._v("中国领先的生物质能源系统服务商")]),_c('div',{staticClass:"foot-text_en"},[_vm._v("CHIMA'S LEADING PROVIDER OF BIOMASS ENERGY SYSTEM")]),_c('p',[_vm._v("地址:中国·青岛市崂山区松岭路330号3号楼F11A、F11B")]),_c('p',[_vm._v("电话:86-0532-55550126")]),_c('p',[_vm._v("传真:huijun@huijun.net.cn")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"foot-c"},[_c('div',{staticStyle:{"font-size":"0.917rem","margin-bottom":"0.625rem","font-family":"'IT'"}},[_vm._v(" NAVIGATION ")]),_c('div',[_vm._v(" 导航中心 "),_c('div',{staticStyle:{"background-color":"#aac93b","width":"2.083rem","height":"0.208rem","margin-top":"0.333rem"}})]),_c('div',{staticClass:"foot-nav"},[_c('div',{staticClass:"nav-wrap"},[_c('a',{attrs:{"href":"#/huijun"}},[_vm._v("走进汇君")])]),_c('div',{staticClass:"nav-wrap"},[_c('a',{attrs:{"href":"#/technologyproducts?id=0"}},[_vm._v("技术与产品")])]),_c('div',{staticClass:"nav-wrap"},[_c('a',{attrs:{"href":"#/news?id=1"}},[_vm._v("新闻中心")])]),_c('div',{staticClass:"nav-wrap"},[_c('a',{attrs:{"href":"#/solution?id=0"}},[_vm._v("解决方案")])]),_c('div',{staticClass:"nav-wrap"},[_c('a',{attrs:{"href":"#/case?id=1"}},[_vm._v("典型案例")])]),_c('div',{staticClass:"nav-wrap"},[_c('a',{attrs:{"href":"#/callUs"}},[_vm._v("联系我们")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"foot-r"},[_c('div',{staticStyle:{"font-size":"0.917rem","margin-bottom":"0.625rem","text-align":"right","font-family":"'IT'"}},[_vm._v(" FOLLOW US ")]),_c('div',{staticClass:"wechat"},[_c('img',{attrs:{"src":require("@/assets/images/wechat2.jpg"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"nav-logo"},[_c('img',{attrs:{"src":require("@/assets/images/logo-ys.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"nav"},[_c('div',{staticClass:"wrap"},[_c('a',{attrs:{"href":"#/huijun"}},[_vm._v("走进汇君")])]),_c('div',{staticClass:"wrap"},[_c('a',{attrs:{"href":"#/technologyproducts"}},[_vm._v("技术与产品")])]),_c('div',{staticClass:"wrap"},[_c('a',{attrs:{"href":"#/news"}},[_vm._v("新闻中心")])]),_c('div',{staticClass:"wrap"},[_c('a',{attrs:{"href":"#/solution"}},[_vm._v("解决方案")])]),_c('div',{staticClass:"wrap"},[_c('a',{attrs:{"href":"#/case"}},[_vm._v("典型案例")])]),_c('div',{staticClass:"wrap"},[_c('a',{attrs:{"href":"#/callUs"}},[_vm._v("联系我们")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticStyle:{"color":"#abbac0","margin-left":"5px"},attrs:{"href":"http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=37021202001670","target":"_blank"}},[_c('img',{staticStyle:{"width":"18px","height":"auto"},attrs:{"src":require("@/assets/images/batb.png")}}),_vm._v("鲁公网安备37021202001670号")])
}]

export { render, staticRenderFns }