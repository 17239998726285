<template>
  <div class="footer">
    <div
      v-if="!phoneSize"
      style="
        display: flex;
        border-bottom: solid 0.083rem #2b586c;
        color: #fff;
        padding-bottom: 0.417rem;
      "
    >
      <div class="foot-l">
        <div class="foot-img"><img src="@/assets/images/logo-d.png" alt="" /></div>
        <div class="foot-text">中国领先的生物质能源系统服务商</div>
        <div class="foot-text_en">CHIMA'S LEADING PROVIDER OF BIOMASS ENERGY SYSTEM</div>
        <p>地址:中国·青岛市崂山区松岭路330号3号楼F11A、F11B</p>
        <p>电话:86-0532-55550126</p>
        <p>传真:huijun@huijun.net.cn</p>
      </div>
      <div class="foot-c">
        <div style="font-size: 0.917rem; margin-bottom: 0.625rem; font-family: 'IT'">
          NAVIGATION
        </div>
        <div>
          导航中心
          <div
            style="
              background-color: #aac93b;
              width: 2.083rem;
              height: 0.208rem;
              margin-top: 0.333rem;
            "
          ></div>
        </div>
        <div class="foot-nav">
          <div class="nav-wrap"><a href="#/huijun">走进汇君</a></div>
          <div class="nav-wrap"><a href="#/technologyproducts?id=0">技术与产品</a></div>
          <div class="nav-wrap"><a href="#/news?id=1">新闻中心</a></div>
          <div class="nav-wrap"><a href="#/solution?id=0">解决方案</a></div>
          <div class="nav-wrap"><a href="#/case?id=1">典型案例</a></div>
          <div class="nav-wrap"><a href="#/callUs">联系我们</a></div>
        </div>
      </div>
      <div class="foot-r">
        <div
          style="
            font-size: 0.917rem;
            margin-bottom: 0.625rem;
            text-align: right;
            font-family: 'IT';
          "
        >
          FOLLOW US
        </div>
        <div class="wechat"><img src="@/assets/images/wechat2.jpg" alt="" /></div>
      </div>
    </div>
    <div
      v-if="phoneSize"
      style="
        width: 100%;
        border-bottom: solid 0.083rem #2b586c;
        color: #fff;
        padding-top: 1.5833rem;
        padding-bottom: 0.417rem;
      "
    >
      <div class="nav-logo"><img src="@/assets/images/logo-ys.png" alt="" /></div>
      <div class="nav">
        <div class="wrap"><a href="#/huijun">走进汇君</a></div>
        <div class="wrap"><a href="#/technologyproducts">技术与产品</a></div>
        <div class="wrap"><a href="#/news">新闻中心</a></div>
        <div class="wrap"><a href="#/solution">解决方案</a></div>
        <div class="wrap"><a href="#/case">典型案例</a></div>
        <div class="wrap"><a href="#/callUs">联系我们</a></div>
      </div>
      <div class="nav-icon">
        <div class="icon-warp" ref="icon1" @click="icon1 = !icon1">
          <i class="el-icon-mobile-phone"></i>
        </div>
        <div class="icon-warp" ref="icon2" @click="icon2 = !icon2">
          <i class="el-icon-folder"></i>
        </div>
        <div class="icon-warp" ref="icon3" @click="icon3 = !icon3">
          <i class="el-icon-map-location"></i>
        </div>
        <div class="icon-warp" ref="icon4" @click="icon4 = !icon4">
          <i class="el-icon-more"></i>
        </div>
        <div class="icon-text">
          <div class="phone" v-if="icon1">电话:86-0532-55550126</div>
          <div class="fax" v-if="icon2">传真:huijun@huijun.net.cn</div>
          <div class="address" v-if="icon3">
            地址:中国·青岛市崂山区松岭路330号3号楼F11A、F11B
          </div>
          <div class="wx" v-if="icon4"></div>
        </div>
      </div>
    </div>
    <div :class="!phoneSize ? 'bot' : ''">
      <div class="text" @click="toBeiAn()">
        Copyright All Rights&nbsp;青岛汇君环境能源工程有限公司&nbsp;鲁ICP备:&nbsp;19002213号-2
        <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=37021202001670" target="_blank" style="color:#abbac0;margin-left: 5px;"><img src="@/assets/images/batb.png" style="width: 18px;height: auto;">鲁公网安备37021202001670号</a>
      </div>
      <div class="text" @click="toXinShiDian()">技术支持&nbsp;|&nbsp;青岛新视点网络</div>
    </div>
    <div style="width:100%;height:1.333rem" v-if="phoneSize"></div>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  data () {
    return {
      // phoneSize: false
      icon1: false,
      icon2: false,
      icon3: false,
      icon4: false,
    }
  },
  computed: {
    ...mapState(["phoneSize"]),
  },
  // 监听
  mounted () {
    // 点击空白弹框隐藏
    document.addEventListener("click", (e) => {
      if( this.icon1 || this.icon2 || this.icon3 || this.icon4){
        if (!this.$refs.icon1.contains(e.target)) this.icon1 = false;
        if (!this.$refs.icon2.contains(e.target)) this.icon2 = false;
        if (!this.$refs.icon3.contains(e.target)) this.icon3 = false;
        if (!this.$refs.icon4.contains(e.target)) this.icon4 = false;
      }

    });
  },
  methods: {
    toBeiAn(){
      window.open('https://beian.miit.gov.cn/#/Integrated/index', '_blank')
    },
    toXinShiDian(){
      window.open('https://www.websitor.cn/', '_blank')
    }
  }
}
</script>
<style scoped>
img {
  width: 100%;
  height: 100%;
}
/* 底部 */
.bot {
  display: flex;
  justify-content: space-between;
  font-size: 0.5rem !important;
}
.nav-icon {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-bottom: 2rem;
  position: relative;
}
.icon-text {
  position: absolute;
  left: 50%;
  bottom: -0.5rem;
  transform: translateX(-50%);
  width: auto;
  text-align: center;
  background-color: #abbac0;
  font-size: 14px;
}
.wx{
  width: 4rem;
  height: 4rem;
  background-image: url(@/assets/images/wechat2.jpg);
  background-repeat: no-repeat;
  background-size: cover;
}
.icon-warp {
  margin: 0 0.8333rem;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #abbac0;
  position: relative;
}
.icon-warp i {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  font-size: 14px;
}
.text {
  line-height: 2.083rem;
  color: #abbac0;
  cursor: pointer;
  font-size: 14px;
  margin-top: 0.833rem;
}
.footer {
  height: auto;
  padding: 0 3.333rem;
  font-size: 0.583rem;
  background-color: #0e4158;
}
.foot-l {
  width: 22.083rem;
  margin-top: 1.25rem;
}
.foot-img{
  width: 14.583333rem;
  margin-bottom: 0.625rem;
}
.foot-text{
  font-size: 0.75rem;
  font-family: YeGenYou;
}
.foot-text_en{
  font-size: 0.75rem;
  transform: scale(0.5);
  margin-bottom: 0.833rem;
  transform-origin: left center;
  white-space: nowrap;
}
.foot-l p {
  line-height: 2;
  font-size: 0.583rem;
}
.foot-c {
  margin: 0 auto;
  padding-top: 1.875rem;
}
.foot-nav {
  width: 22.917rem;
  display: flex;
  flex-wrap: wrap;
}
.nav-wrap {
  width: 6.25rem;
  margin: 1.25rem 2.083rem 0 0;
  transition: all 0.6s ease;
}
.nav-wrap a {
  color: #fff;
}
.nav-wrap:hover a {
  color: #aac93b;
}
.nav-wrap:nth-child(3n) {
  margin-right: 0;
}
.foot-r {
  width: 6.667rem;
  position: relative;
  padding-top: 1.875rem;
}
.wechat {
  position: absolute;
  right: 0.333rem;
  top: 5.417rem;
  width: 3.333rem;
  height: 3.333rem;
}
.nav-logo {
  width: 18.75rem;
  height: 2.5rem;
  margin: 0 auto;
}
.nav {
  margin: 1rem 0;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.wrap {
  width: 30%;
  text-align: center;
  position: relative;
  padding: 0 0.8333rem;
  line-height: 1.5;
}
.wrap:after {
  content: '';
  display: block;
  height: 12px;
  width: 0.0833rem;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-0.5rem);
  background-color: #fff;
}
.wrap:nth-child(3n):after{
  display: none;
}
.wrap a {
  color: #fff;
  font-size: 14px;
}
</style>
