<template>
  <div>
    <div class="main">
      <div class="main-bg"></div>
      <div class="main-text">
        <p class="text-title" style="margin: 0.417rem 0">典型案例</p>
        <p class="text-title-en">SOLUTION</p>
      </div>
    </div>
    <div class="nav-head" v-if="!phoneSize">
      <div class="nav-title">
        <el-menu
          :default-active="activeIndex"
          class="el-menu-demo"
          mode="horizontal"
          text-color="#fff"
          background-color="transparent"
          active-text-color="#fff"
        >
          <el-menu-item
            :index="item.id"
            v-for="item in cateList"
            :key="item.id"
            @click="changeCate(item.id)"
          >
            {{ item.title }}
          </el-menu-item>
        </el-menu>
      </div>
      <div class="nav-text">
        <div style="margin-right: 0.625rem; width: 1.042rem; height: 1.042rem">
          <img src="@/assets/images/icon/icon-house.png" alt="" />
        </div>
        <div class="right-nav">
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>典型案例</el-breadcrumb-item>
            <el-breadcrumb-item v-if="cateId == 1">沼气工程项目</el-breadcrumb-item>
            <el-breadcrumb-item v-else-if="cateId == 2"
              >餐厨厨余垃圾处理项目</el-breadcrumb-item
            >
            <el-breadcrumb-item v-else-if="cateId == 3">污水处理项目</el-breadcrumb-item>
            <el-breadcrumb-item v-else>有机肥发酵罐</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
      </div>
    </div>
    <div class="nav-two" v-if="phoneSize">
      <div
        class="two-wrap"
        v-for="item in cateList"
        :key="item.id"
        @click="changeCate(item.id)"
      >
        <div class="two-text">{{ item.title }}</div>
      </div>
    </div>
    <div class="project">
      <!-- 锚点 -->
      <div id="fj" style="width:0.042rem;height:0.042rem"></div>
      <div class="project-main">
        <div class="wrap" :class="phoneSize ?'phone-wrap':''" v-for="item in casesList" :key="item.index" @mouseenter="showIntroduce(item.id)" @mouseleave="introduceShow = false">
          <div class="image">
            <!-- 简介弹框 -->
            <div class="show" :class="[chooseId == item.id ? 'opacity':'', phoneSize ? 'size1':'size3']" v-if="introduceShow" v-html="item.content"></div>
            <img :src="$pregImg + item.thumb" alt="" /></div>
          <p :class="phoneSize ? 'size2':''">{{ item.title }}</p>
        </div>
      </div>
      <div class="project-foot">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pageData.pageCount"
          :page-size="pageData.pageSize"
          layout="prev, pager, next, jumper"
          :total="pageData.total"
        >
        </el-pagination>
      </div>
    </div>
    <foot></foot>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  data () {
    return {
      activeIndex: '1',
      cateId: 1,
      cateList: [], //标题列表
      casesList: [], //方案列表
      pageData: {
        pageCount: 1, //当前页
        pageSize: 6, //条数
        total: 6, //总共条数
      },
      introduceShow:false, //项目简介展开
      chooseId:0,
    };
  },
  watch: {
    '$route': function () {
      const id = this.$route.query.id
      this.activeIndex = id
      this.cateId = id
      this.queryList()
      if(id != 1){
        this.toScroll()
      }
    }
  },
  mounted () {
    const id = this.$route.query.id
    this.activeIndex = id
    this.cateId = id
    this.queryList()
    // this.$axios.get('/api/cases/category').then((res) => {
      this.$axios.get('cases/category').then((res) => {
      this.cateList = res.data.data.list
    })
    if(id != 1){
      this.toScroll()
    }
  },
  computed: {
    ...mapState(["phoneSize"]),
  },
  methods: {
    changeCate (n) {
      this.cateId = n
      this.queryList()
    },
    // 条数
    handleSizeChange (val) {
      this.pageData.pageSize = val
      this.queryList()
    },
    // 页数
    handleCurrentChange (val) {
      this.pageData.pageCount = val
      this.queryList()
    },
    // 更新工作数据
    queryList () {
      // this.$axios.get('/api/cases/index?cate_id=' + this.cateId + '&page=' + this.pageData.pageCount + '&pagesize=' + this.pageData.pageSize).then((res) => {
        this.$axios.get('cases/index?cate_id=' + this.cateId + '&page=' + this.pageData.pageCount + '&pagesize=' + this.pageData.pageSize).then((res) => {
        this.casesList = res.data.data.list
        this.pageData.total = res.data.data.total_count
      })
    },
    //展示项目简介
    showIntroduce(n){
      this.chooseId = n
      this.introduceShow = true
    },
    // 锚点
    toScroll(){
      this.$el.querySelector('#fj').scrollIntoView({behavior: "smooth",block: "center",});
    },
  }
}
</script>
<style>
.nav-two {
  width: 100%;
  padding: 0.8333rem 2.5%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 4.1667rem 0;
}
.two-wrap {
  width: 20%;
  height: auto;
  text-align: center;
  border-radius: 0.4167rem;
  background-color: #454242;
  margin: 0.8333rem 5% 0 0;
  padding: 0.8333rem;
  line-height: 1.5;
  color: #fff;
  cursor: pointer;
}
.two-wrap:nth-child(4n) {
  margin-right: 0;
}
.two-wrap:hover .two-text {
  color: #a6c142;
}
</style>
<style scoped>
  .size{
    font-size: 14px !important;
  }
  .size2{
    font-size: 15px !important;
  }
.text-title {
  font-size: 2.667rem;
  font-family: ssb;
}
.text-title-en {
  font-size: 1.5rem;
  font-family: Arial, Helvetica, sans-serif;
}
img {
  width: 100%;
  height: 100%;
}
/* 走进汇君 */
.main {
  width: 100%;
  padding-top: 0.042rem;
  height: 36.667rem;
  overflow: hidden;
  position: relative;
}
.main-bg{
  position: absolute;
  width: 100%;
  height: 100%;
  background: url(@/assets/images/case/caseBg-00.jpg) no-repeat center;
  background-size: cover;
  animation: zoom_big2 16s ease-out infinite;
  z-index: -1;
}
@keyframes zoom_big2 {
  0%{
    transform: scale(1);
  }
  60% {
    transform: scale(1.05);
  }
  95% {
    transform: scale(1);
  }
  100% {
    transform: scale(1);
  }
}
.main-text {
  margin: 15.417rem 0 0 3.333rem;
  color: #fff;
}

/* 分类导航 */
.nav-head {
  position: relative;
  margin-bottom: 9.167rem;
}
.nav-title {
  width: 54.167rem;
  height: 6.667rem;
  background-image: linear-gradient(to right, #72caed, #26b1e0);
  border-radius: 0 3.75rem 0 0;
  position: absolute;
  left: 0;
  top: -3.75rem;
  z-index: 11;
  padding: 0 0 0 4.167rem;
}
.el-menu-item:hover{
  color: #fff !important;
}
.el-menu-item{
  color: rgba(255, 255, 255, 0.7) !important;
}
.nav-text {
  width: 41.667rem;
  height: 6.25rem;
  background-image: linear-gradient(to right, #e6eeca, #a6c142);
  border-radius: 0 0 0 2.083rem;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.right-nav {
  margin-right: 3.333rem;
}
/* 项目 */
.project {
  width: 100%;
  padding: 0 3.666rem;
}
.project-main {
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
}
.wrap {
  width: 47.4%;
  height: auto;
  margin-right: 5%;
  margin-bottom: 1.25rem;
  padding-bottom: 1.25rem;
  border-radius: 0.75rem;
  border-bottom: 0.167rem solid #a6c142;
  overflow: hidden;
  text-align: center;
  background: #f3f3f3;
  transition: all 0.5s ease;
}
.phone-wrap{
  width: 100% !important;
  margin-right: 0;
}
.wrap:nth-child(2n) {
  margin-right: 0;
}
.image {
  width: 100%;
  height: auto;
  overflow: hidden;
  position: relative;
}
.show{
  opacity: 0;
  position:absolute;
  background-color: rgba(14, 65, 88, 0.8);
  width:100%;
  height: 100%;
  padding: 2rem 1.667rem 0;
  left: 0;
  top: 0;
  z-index:100;
  color: #fff;
  text-align: left;
  overflow: auto;
}
.opacity{
  opacity: 1;
}
.size3 /deep/ p{
  line-height: 2 !important;
}
.size1 /deep/ p{
  line-height: 1.6 !important;
  text-align:justify;
}
.image img {
  transition: all 0.6s ease;
}
.wrap:hover{
  background-color: #a6c142;
  color: #fff;
}
.wrap:hover img {
  transform: scale(1.05);
}
.wrap p {
  margin-top: 2.083rem;
  font-size: 1.042rem;
}
.project-foot {
  width: 100%;
  margin: 4.167rem 0;
}
/* 样式覆盖 */
.nav-head >>> .el-breadcrumb {
  font-size: 0.583rem;
}
.nav-head >>> .el-menu.el-menu--horizontal {
  height: 6.67rem;
  border-bottom: solid 0 #e6e6e6;
}
.nav-head >>> .el-menu--horizontal > .el-menu-item {
  height: 6.67rem;
  line-height: 6.67rem;
  font-size: 0.917rem;
  text-align: center;
}
.nav-head >>> .el-breadcrumb__inner.is-link {
  color: #fff;
}
.nav-head >>> .el-breadcrumb__separator {
  color: #fff;
}
.nav-head >>> .el-breadcrumb__item .el-breadcrumb__inner {
  color: #fff;
}
.nav-head >>> .el-menu .el-menu-item:after {
  content: '';
  display: block;
  width: 0.042rem;
  height: 0.833rem;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  background-color: #fff;
}
.el-menu .el-menu-item:last-child:after {
  display: none;
}
.nav-head >>> .el-menu--horizontal .el-menu-item.is-active {
  border-bottom: 0.167rem solid #aac93b !important;
}
</style>