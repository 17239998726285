<template>
  <div>
    <top></top>
    <router-view></router-view>
  </div>
</template>
<script>
import {  mapMutations } from "vuex";
export default {
  data () {
    return {
    }
  },
  created(){
    let w = window.innerWidth;
    this.setFontSize (w)
  },
  mounted(){
    let w = window.innerWidth;
    this.phoneWhite(w); // 组件初始化的时候不会触发onresize事件，这里强制执行一次
    window.onresize = () => {
      let w = window.innerWidth;
      if (!this.timer) { // 使用节流机制，降低函数被触发的频率
        this.timer = true;
        let that = this; // 匿名函数的执行环境具有全局性，为防止this丢失这里用that变量保存一下
        setTimeout(function () {
          that.phoneWhite(w);
          that.setFontSize(w)
          that.timer = false;
        }, 400)
      }
    }
  },
  methods:{
    // 判断是否手机端
    ...mapMutations({ phoneWhite: 'ifPhone' }),
    setFontSize (w) {
      // 确定基数
      var i = 80
      // // 获取设备
      const htmlClient = document.documentElement
      // // 设备宽度
      // let htmlWidth = htmlClient.offsetWidth
      // 设备宽度不能无限的大不能无限小 (750 - 1920)
      // if (htmlWidth >= 2560) {
      //   htmlWidth = 2560
      // }
      // else if(htmlWidth <= 992){
      //   htmlWidth = 992
      // }
      if(w <= 840){
        i = 48
      }
      // 设置根标签文字大小
      htmlClient.style.fontSize = w / i + 'px'
      // console.log(htmlClient.style.fontSize);
    }
  }

}
</script>

<style>
</style>
