<template>
  <div>
    <div class="main">
      <div class="sign" ref="sign"></div>
      <div class="main-bg"></div>
      <div class="main-text">
        <p class="text-title" style="margin: 0.417rem 0">技术与产品</p>
        <p class="text-title-en">TECHNOLOGY AND PRODUCTS</p>
      </div>
    </div>
    <div class="nav-head"
    ref="element" v-if="!phoneSize">
      <!-- <div class="nav-title"
      :class="navFixed ? 'nav-fixed':''"
      > -->
      <div class="nav-title"
      >
        <el-menu
          :default-active="activeIndex"
          class="el-menu-demo"
          mode="horizontal"
          text-color="#fff"
          background-color="transparent"
          active-text-color="#fff"
        >
          <el-menu-item
            :index="item.cate_id"
            v-for="item in productData"
            :key="item.cate_id"
            @click="toScroll(item.cate_id)"
          >
            {{ item.cate_title }}
          </el-menu-item>
        </el-menu>
      </div>
      <div class="nav-text">
        <div style="margin-right: 0.625rem; width: 1.042rem; height: 1.042rem">
          <img src="@/assets/images/icon/icon-house.png" alt="" />
        </div>
        <div class="right-nav">
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item :to="{path:'technologyproducts',query:{id:0}}">技术与产品</el-breadcrumb-item>
            <el-breadcrumb-item v-if="cateId == 1">前端处理</el-breadcrumb-item>
            <el-breadcrumb-item v-else-if="cateId == 2">厌氧发酵</el-breadcrumb-item>
            <el-breadcrumb-item v-else-if="cateId == 3">好氧发酵</el-breadcrumb-item>
            <el-breadcrumb-item v-else-if="cateId == 4">除臭工艺</el-breadcrumb-item>
            <el-breadcrumb-item v-else-if="cateId == 5">净化提纯</el-breadcrumb-item>
            <el-breadcrumb-item v-else-if="cateId == 6">污水处理</el-breadcrumb-item>
            <el-breadcrumb-item v-else-if="cateId == 7">有机肥产品</el-breadcrumb-item>
            <el-breadcrumb-item v-else-if="cateId == 8">有机废弃物智能收运系统</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
      </div>
    </div>
    <div class="nav-two" v-if="phoneSize">
      <div
        class="two-wrap"
        :index="item.cate_id"
        v-for="item in productData"
        :key="item.cate_id"
        @click="toScroll(item.cate_id)"
      >
        <div class="two-text">{{ item.cate_title }}</div>
      </div>
    </div>
    <!-- 项目 -->
    <div>
      <!-- 视频 -->
      <div class="container" v-if="playerShow">
        <video-player
          class="video-player vjs-custom-skin"
          ref="videoPlayer"
          :playsinline="true"
          :options="playerOptions"
        ></video-player>
      </div>
      <div class="project">
        <!-- 技术分类列表 -->
        <div class="project-main" 
        ref="project"
        v-for="product in productData" 
        :key=" product.cate_id"
        :class="[barType(product.cate_id)]">
          <h2 style="margin:0.83rem 0 0.417rem 0;font-size: 1.25rem" :class="phoneSize ? 'blue':''">{{product.cate_title}}</h2>
          <div class="projects">
            <!-- 技术小分类 -->
            <div class="wrap" :class="phoneSize ?'phone-wrap':'' " v-for="(item, index) in product.child" :key="index">
              <div @click="toDetail(item.id,product.cate_id)">
                <div class="image" :class="phoneSize ?'phone-image':'' "><img :src="$pregImg + item.thumb" alt="" /></div>
                <div class="news-title"  :class="phoneSize ? 'size':''">
                  {{ item.title }}
                </div>
                <!-- <div class="news-text">{{ item.description }}</div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <foot></foot>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { videoPlayer } from 'vue-video-player'
import 'video.js/dist/video-js.css'
import 'vue-video-player/src/custom-theme.css'
export default {
  components: { videoPlayer },
  data () {
    return {
      activeIndex: '0',
      cateId: 0, //标题id
      // navIndex:0, //大分类导航
      // navFixed:false, //大分类导航固定
      // navHeight:0,
      // signHeight:0,
      // height:0,
      cateList: [], //标题列表
      cateInfo: {}, // 视频信息
      productData: [], //技术列表
      playerShow:false, // 视频显示
      playerOptions: {
        playbackRates: [0.7, 1.0, 1.25, 1.5, 2.0], // 播放速度
        autoplay: false, // 如果true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 导致视频一结束就重新开始。
        preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: 'zh-CN',
        aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时, player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [
          {
            type: 'video/mp4', // 类型
            src: 'http://www.huijun.net.cn/uploads/video/2df970787b517bba3d83f0d9471d66eb.mp4', //url地址
          },
        ],
        poster: "http://www.huijun.net.cn/uploads/images/product/images/1660535767.jpg", // 封面地址
        notSupportedMessage: '此视频暂无法播放，请稍后再试', // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true, // 当前时间和持续时间的分隔符
          durationDisplay: true, // 显示持续时间
          remainingTimeDisplay: false, // 是否显示剩余时间功能
          fullscreenToggle: true, // 全屏按钮
        },
      },
    };
  },
  mounted () {
    // this.$axios.get('/api/product/category').then((res) => {
      this.$axios.get('product/category').then((res) => {
      this.productData = res.data.data.list
    })
    setTimeout(()=>{
      const id = this.$route.query.id
      if(id == 0){
        this.playerShow = true
        this.cateId = id
        this.activeIndex = id
      }else{
        this.$nextTick(() => {
          this.toScroll(id)
        })
      }
    },200)
  },
  computed: {
    ...mapState(["phoneSize"]),
  },
  watch: {
    '$route': function () {
      this.playerShow = false
      this.$nextTick(()=>{
        const id = this.$route.query.id
        if(id == 0){
          this.playerShow = true
          this.cateId = id
          this.activeIndex = id
        }else{
          this.toScroll(id)
        }
      })
    },
  },
  methods: {
    toDetail (n,m) {
      this.$router.push({ path: 'productsDetail', query: { id: n,cate_id: m} })
    },
    // 动态添加类
    barType(index){
      const com = 'product'
      if(index>=0){
        return (`${com}`+index)
      }
    },
    toScroll (n) {
      this.playerShow = false
      this.cateId = n
      this.activeIndex = n
      const name = 'product' 
      this.$el.querySelector('.'+`${name}`+n).scrollIntoView({behavior: "smooth",block:"center"});
    },
  },
  // 离开当前组件前一定要清除滚动监听，否则进入其他路由会报错
  beforeRouteLeave (to, from, next) {
    window.removeEventListener('scroll', this.handleTabFix, true)
    next()
  },
}
</script>
<style scoped>
  .size{
    font-size: 14px !important;
  }
.el-menu-item:hover{
  color: #fff !important;
}
.el-menu-item{
  color: rgba(255, 255, 255, 0.7) !important;
}
.el-menu-item.active{
  border-bottom: 0.125rem solid #aac93b !important;
}
.text-title {
  font-size: 2.667rem;
  font-family: ssb;
}
.text-title-en {
  font-size: 1.5rem;
  font-family: Arial, Helvetica, sans-serif;
}
img {
  width: 100%;
  height: 100%;
}
/* 走进汇君 */
.main{
  width: 100%;
  padding-top: 0.042rem;
  height: 36.667rem;
  overflow: hidden;
  position: relative;
}
.sign{
  width: 0.042rem;
  height: 0.042rem;
  position: absolute;
  right: 0;
  top: 4rem;
  background: #fff;
  opacity: 0.000001;
}
.main-bg{
  position: absolute;
  width: 100%;
  height: 100%;
  background: url(@/assets/images/products/product.jpg) no-repeat center;
  background-size: cover;
  animation: zoom_big2 16s ease-out infinite;
  z-index: -1;
}
@keyframes zoom_big2 {
  0%{
    transform: scale(1);
  }
  60% {
    transform: scale(1.05);
  }
  95% {
    transform: scale(1);
  }
  100% {
    transform: scale(1);
  }
}
.main-text {
  margin: 15.417rem 0 0 3.333rem;
  color: #fff;
}

/* 分类导航 */
.nav-head {
  position: relative;
  margin-bottom: 9.167rem;
}
.nav-title {
  width: 54.167rem;
  height: 6.667rem;
  background-image: linear-gradient(to right, #72caed, #26b1e0);
  border-radius: 0 3.75rem 0 0;
  position: absolute;
  left: 0;
  top: -3.75rem;
  z-index: 11;
  padding: 0 0 0 4.167rem;
}
.nav-text {
  width: 41.667rem;
  height: 6.25rem;
  background-image: linear-gradient(to right, #e6eeca, #a6c142);
  border-radius: 0 0 0 2.083rem;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.right-nav {
  margin-right: 3.333rem;
}
.container {
  width: 46.666666rem;
  max-width: 100%;
  height: 26.25rem;
  margin: 0 auto;
  overflow: hidden;
}
.container>>>.vjs-poster {
  background-size: cover !important;
}
/* 项目 */
.project {
  width: 100%;
  margin-bottom: 5rem;
}
.project-nav{
  display: flex;
  margin: 1.667rem 4rem 1.667rem 4rem;
  border: 1px solid #000;
  width: 71.833rem;
}
.wrap-nav{
  width: 12.5%;
  height: auto;
  padding: 0.833rem 0;
  text-align: center;
  border-right: 1px solid #000;
  font-size: 0.75rem;
  cursor: pointer;
  background-color: #fff;
  z-index: 100;
}
.chooseNav{
  background: #000;
  color: #fff;
}
.showNav{
  display: none;
  border: none;
}
.wrap-nav:last-child{
  border-right: none;
}
.project-main{
  padding: 1.667rem 4rem;
  background: #efefef;
}
.project-main:nth-child(odd){
  background: #fff;
}
.projects {
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
}
.wrap {
  width: 29.33%;
  height: auto;
  margin-bottom: 1.25rem;
  margin-right: 6%;
}
.phone-wrap{
  width: 100% !important;
  height: auto;
  margin-right: 0 !important;
}
.wrap:nth-child(3n) {
  margin-right: 0;
}
.image {
  width: 100%;
  height: 12.792rem;
  overflow: hidden;
}
.phone-image{
  height: auto !important;
}
.wrap:hover .news-title {
  color: #28b2e1;
}
.blue{
  border-left: .5rem solid #28b2e1;
  padding-left: 1.25rem;
  margin-bottom: 2.5rem !important;
  font-size: 15px !important;
}
.wrap .news-title {
  width: 100%;
  margin: 1.25rem 0.417rem;
  font-size: 0.667rem;
  font-weight: 550;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
/* 样式覆盖 */
.nav-head >>> .el-breadcrumb {
  font-size: 0.583rem;
}
.nav-head >>> .el-menu.el-menu--horizontal {
  height: 6.67rem;
  border-bottom: solid 0 #e6e6e6;
}
.nav-head >>> .el-menu--horizontal > .el-menu-item {
  height: 6.67rem;
  line-height: 6.67rem;
  font-size: 0.917rem;
  text-align: center;
}
.nav-head >>> .el-breadcrumb__inner.is-link {
  color: #fff;
}
.nav-head >>> .el-breadcrumb__separator {
  color: #fff;
}
.nav-head >>> .el-breadcrumb__item .el-breadcrumb__inner {
  color: #fff;
}
.container >>> .vjs-poster {
  width: 46.666666rem;
  height: 26.25rem;
}
.container >>> .vjs-custom-skin > .video-js .vjs-big-play-button {
  border-radius: 50% !important;
  height: 2em !important;
  width: 2em !important;
}
.nav-head >>> .el-menu .el-menu-item:after {
  content: '';
  display: block;
  width: 0.042rem;
  height: 0.833rem;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  background-color: #fff;
}
.el-menu .el-menu-item:last-child:after {
  display: none;
}
.nav-head >>> .el-menu--horizontal>.el-menu-item.is-active {
  border-bottom: 0.125rem solid #aac93b !important;
}
</style>