<template>
  <div class="container page" id="home" :class="phoneSize ?'size':''" >
    <div class="swiper-container">
      <div class="swiper-wrapper" style="height: 38.667rem">
        <!-- 首页 -->
        <div class="swiper-slide" :class="phoneSize ?'phone-box1':'box1'">
          <!-- banner轮播 -->
          <div class="swiper-container3">
            <div class="swiper-wrapper" style="width: 80rem; height: 38.667rem">
              <div
                class="swiper-slide"
                :class="phoneSize? 'backimg':''"
                style="width: 100%"
                v-for="item in banner"
                :key="item.index"
              >
                <img :src="$pregImg + item.thumb" />
              </div>
            </div>
          </div>
          <div class="content1 wrap ani"
          swiper-animate-effect="fadeInUp"
          swiper-animate-duration="0.8s"
          swiper-animate-delay="0.2s">
            <div class="text1"></div>
            <!-- <span class="text1" style="margin-right: 3.333rem">汇能于境</span><span class="text1">君卓于行</span> -->
            <p style="font-size: 1.167rem" v-if="!phoneSize">中国领先的生物质能源系统服务商</p>
            <p style="font-size: 14px" v-if="phoneSize">中国领先的生物质能源系统服务商</p>
            <p style="font-size: 0.5rem">
              CHINA'S LEADING PROVIDER OF BIOMASS ENERGY SYSTEM
            </p>
          </div>
          <div class="mouse"
          :class="phoneSize ? 'mouse-pad':''">
            <div class="mouse-icon"><img src="@/assets/images/icon/mouse.png" alt=""></div>
            <div class="mouse-text" v-if="!phoneSize">SCROLL</div>
            <div class="mouse-text" style="font-size: 14px" v-if="phoneSize">SCROLL</div>
          </div>
        </div>
        <!-- 走进汇君 -->
        <div class="swiper-slide" :class="phoneSize ?'phone-box2':'box2'">
          <div class="content2 wrap" 
          >
            <div class="ani"
              swiper-animate-effect="fadeIn"
              swiper-animate-duration="0.5s">
                <div class="mini-title1">INTO THE HUIJUN</div>
                <div :class="phoneSize ?'phone-title1':'title1'">走进汇君</div>
                <div class="detail" :class="phoneSize ? 'detail-pad':''" @click="toDetail">了解详情</div>
            </div>
            <div class="hj-text  ani"
              :class="phoneSize ?'size1':''"
              swiper-animate-effect="fadeInUp"
              swiper-animate-duration="1.5s"
              swiper-animate-delay="0.5s" style="margin-top: 2.083rem" v-html="about"></div>
            </div>
        </div>
        <!-- 解决方案 -->
        <div class="swiper-slide" :class="phoneSize ?'phone-box3':'box3'">
          <div class="box3-img backimg"
          :class="[ imgUrl == item.thumb ? 'active':'',phoneSize? 'backimg':'']"
          style="width: 100%;
          height: 100%"
          v-for="(item, index) in solution"
          :key="index">
            <img :src="$pregImg + item.thumb" />
          </div>
          <div :class="phoneSize ? 'phoneBox3' : 'box3-text'" >
            <div class="centent-l ani"
            swiper-animate-effect="fadeInUp"
            swiper-animate-duration="2s"
            swiper-animate-delay="0.1s">
              <div style="font-size: 1.292rem" v-if="!phoneSize">汇君环能</div>
              <div style="font-size: 16px" v-if="phoneSize">汇君环能</div>
              <div
                style="
                  width: 4.167rem;
                  height: 0.417rem;
                  background-color: #aac93b;
                  margin: 0.833rem 0 0 0.333rem;
                "
              ></div>
              <div style="display: flex">
                <div
                  style="
                    width: 2.917rem;
                    font-size: 4.625rem;
                    font-family: AE;
                    position: relative;
                  "
                >
                  3
                  <div class="title-fixed">
                    <p style="font-size: 1.75rem; font-family: ssh" v-if="!phoneSize">解决方案</p>
                    <p style="font-size: 18px; font-family: ssh" v-if="phoneSize">解决方案</p>
                    <p style="font-size: 0.75rem" v-if="!phoneSize">为客户提供可靠、专业、全方位的系统服务</p>
                    <p style="font-size: 14px" v-if="phoneSize">为客户提供可靠、专业、全方位的系统服务</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="centent-r ani"
            swiper-animate-effect="fadeInDown"
            swiper-animate-duration="2s"
            swiper-animate-delay="0.1s"
             :class="phoneSize ? 'top' : ''">
              <div
                :class="phoneSize ? 'phoneBanNav' : 'banNav'"
                v-for="(item, index) in solution"
                :key="index"
                v-on:mouseenter="enterNav(item.thumb)"
                @click="toSolution(index)"
              >
                <div class="nav-num">0{{ index + 1 }}</div>
                <div class="nav-text">
                  <p style="font-size: 0.75rem" v-if="!phoneSize">{{ item.title_en }}</p>
                  <p style="font-size: 14px" v-if="phoneSize">{{ item.title_en }}</p>
                  <p class="text-z" v-if="!phoneSize">{{ item.title }}</p>
                  <p class="text-z" style="font-size: 16px" v-if="phoneSize">{{ item.title }}</p>
                </div>
                <div class="banNav_line" :class="[time == index * 6 ? 'on' : '']">
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 技术与产品 -->
        <div class="swiper-slide"  :class="phoneSize ?'phone-box4':'box4'">
          <div class="content4 wrap">
            <div class="mini-title1" style="color: #6a9ebd">TECHNOLOGY AND PRODUCTS</div>
            <div :class="phoneSize ?'phone-title1':'title1'">技术与产品</div>
          </div>
          <div style="margin-top:3.5rem;">
            <!-- PC端 -->
            <div class="swiper-container2" v-if="!phoneSize">
              <div class="swiper-wrapper">
                <div class="swiper-slide" v-for="(item, index) in product" :key="index" @click="toTech(item.cate_id)">
                  <img :src="$pregImg + item.thumb" />
                  <div class="img-title">{{ item.cate_title }}</div>
                </div>
              </div>
            </div>
            <!-- 手机端 -->
            <div class="swiper-container4 phoneTop" v-if="phoneSize">
              <div class="swiper-wrapper">
                <div class="swiper-slide" v-for="(item, index) in product" :key="index" @click="toTech(item.cate_id)">
                  <div class="product-title">{{ item.cate_title }}</div>
                  <img :src="$pregImg + item.thumb" />
                  <div class="img-title size">{{ item.cate_title }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="lb-foot" v-if="phoneSize">
            <div class="lb-wrap" v-for="(item, index) in product" :key="index" @click="control4(index)">
              <div class="phoneCircle" v-if="phoneSize" :class="[isIndex == item.cate_id ? 'green' : '']">
              </div>
            </div>
          </div>
          <!-- 轮播底部 -->
          <!-- <div :class="phoneSize ? 'phone-lb-foot':'lb-foot'">
            <div class="lb-wrap" :class="phoneSize ? 'lb-wrap-top':''" v-for="(item, index) in product" :key="index" @mouseenter="control(index)">
              <div class="lb-line" v-if="!phoneSize"></div>
              <div class="circle" v-if="!phoneSize">
                <span class="round" :class="[isIndex == item.cate_id ? 'green' : '']"></span>
              </div>
              <div class="phoneCircle" v-if="phoneSize" :class="[isIndex == item.cate_id ? 'green' : '']">
              </div>
              <p>{{ item.cate_title }}</p>
            </div>
          </div> -->
          <div class="lb-foot" v-if="!phoneSize">
            <div class="lb-wrap" v-for="(item, index) in product" :key="index" @mouseenter="control(index)">
              <div class="lb-line"></div>
              <div class="circle">
                <span class="round" :class="[isIndex == item.cate_id ? 'green' : '']"></span>
              </div>
              <div class="phoneCircle" v-if="phoneSize" :class="[isIndex == item.cate_id ? 'green' : '']">
              </div>
              <p>{{ item.cate_title }}</p>
            </div>
          </div>
        </div>
        <!-- 底部 -->
        <div class="swiper-slide box5">
          <foot></foot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
let vm = null
import { mapState,mapMutations } from "vuex";
import Swiper from "swiper"
export default {
  data () {
    let that = this;
    return {
      isIndex: 2,
      slideIndex:1,
      time: 0,
      imgUrl: '/uploads/images/solution/thumb/1661853566.jpg', //默认解决方案背景图
      banner: [], //banner图
      about: '', //走进汇君
      product: [], //技术与产品
      solution: [], //解决方案
    }
  },
  computed: {
    ...mapState(["phoneSize","headShow"]),
  },
  mounted () {
    this.navTimeUpdate()
    vm = this
    // this.$axios.get('/api/home/index').then((res) => { // 获取首页数据
    this.$axios.get('home/index').then((res) => { // 获取首页数据
      this.banner = res.data.data.banner
      this.about = res.data.data.about
      this.product = res.data.data.product
      this.solution = res.data.data.solution
      this.$nextTick(()=>{
        this.bannerPoint()
        this.bannerPoint2()
      })
    })
  },
  watch: {
    slideIndex:function(val,oldVal){
      // 第一页无背景，其他页都有背景
      val == 1 ? this.showHead(true):this.showHead(false)
      if(val == 3){
        this.enterNav(this.imgUrl)
      }
    }
  },
  methods: {
    ...mapMutations({ showHead: 'ifHeadShow' }),
    control(m){
      var swiper2 = new Swiper('.swiper-container2', {
          loop:true,
          slidesPerView: 'auto',  // 默认一个屏幕显示几张图，必须auto！要不轮播到最后一张图时，右侧会是空白，很丑！
          loopedSlides: 10,
          centeredSlides: true, // 每屏，居中显示
          on: {
            slideChange: function () {
              vm.isIndex = this.realIndex + 1
            },
          },
          observer: true,
          observeParents: true,
          observeSlideChildren: true,
          initialSlide :1,
      })
      swiper2.slideTo(m+8, 1000, false)
    },
    control4(n){
      var swiper4 = new Swiper('.swiper-container4', {
          loop:true,
          slidesPerView: 'auto',  // 默认一个屏幕显示几张图，必须auto！要不轮播到最后一张图时，右侧会是空白，很丑！
          loopedSlides: 10,
          on: {
            slideChange: function () {
              vm.isIndex = this.realIndex + 1
            },
          },
          observer: true,
          observeParents: true,
          observeSlideChildren: true,
          initialSlide :1,
      })
      swiper4.slideTo(n+8, 1000, false)
    },
    enterNav (url) {
      this.imgUrl = url
    },
    toDetail () {
      this.$router.push('/huijun')
    },
    bannerPoint () {
      vm = this
      new Swiper('.swiper-container', {
        direction: 'vertical',
        mousewheel: {
          releaseOnEdges: true,//PC端释放滑动    
        },
        speed: 800,
        slidesPerView: 'auto',
        on:{
          slideChange: function () {
            vm.slideIndex = this.realIndex + 1
            vm.$swiperAnimate(this);
          },
          init: function () {
            vm.$swiperAnimateCache(this); //隐藏动画元素
            vm.$swiperAnimate(this); //初始化完成开始动画
          },
        },
        observer: true,
        observeParents: true,
        observeSlideChildren: true,
      }),
      new Swiper('.swiper-container3', {
        loop:true,
        effect: 'fade',
        autoplay: {
          delay: 3000,  // 多长时间进行一次自动切换
          disableOnInteraction: false,  // 用户点击一次后是否取消自动切换 默认true
        },
        speed: 300, // 切换速度
        observer: true,
        observeParents: true,
        observeSlideChildren: true,
      })
    },
    bannerPoint2 () {
      vm = this
      new Swiper('.swiper-container2', {
        loop:true,
        slidesPerView: 'auto',  // 默认一个屏幕显示几张图，必须auto！要不轮播到最后一张图时，右侧会是空白，很丑！
        loopedSlides: 10,
        centeredSlides: true, // 每屏，居中显示
        on: {
          slideChange: function () {
            vm.isIndex = this.realIndex + 1
          },
        },
        observer: true,
        observeParents: true,
        observeSlideChildren: true,
        initialSlide :1,
      }),
      new Swiper('.swiper-container4', {
        loop:true,
        slidesPerView: 'auto',  // 默认一个屏幕显示几张图，必须auto！要不轮播到最后一张图时，右侧会是空白，很丑！
        loopedSlides: 10,
        on: {
          slideChange: function () {
            vm.isIndex = this.realIndex + 1
          },
        },
        observer: true,
        observeParents: true,
        observeSlideChildren: true,
        initialSlide :1,
      })
    },
    // 解决方案动画
    navTimeUpdate () {
      var a = 0
      var navTime = setInterval(() => {
        a++;
        if (a > 0 && a < 6) {
          this.time = 0
        } else if (a >= 6 && a < 12) {
          this.time = 6
        } else if (a >= 12 && a < 18) {
          this.time = 12
        } else if (a = 18) {
          a = 0
          this.time = 0
        }
      }, 1000)
    },
    toSolution(m){
      let id = m+1
      this.$router.push({path:'solution',query:{id:id}})
    },
    toTech(n){
      if(n){
        this.$router.push({path:'technologyproducts',query:{id:n}})
      }else{
        this.$router.push({path:'technologyproducts',query:{id:0}})
      }
    }
  },
}
</script>
<style scoped>
  .size{
    font-size: 14px !important;
  }
.hj-text /deep/p {
  line-height: 2 !important;
  font-size: 16px !important;
}
.size1 /deep/ p{
  font-size: 14px !important;
  line-height: 1.6 !important;
  text-align: justify;
}
img {
  width: 100%;
  height: 100%;
}
.page {
  width: 100%;
}
/* 换屏背景设置 */
.swiper-container {
  height: 100vh;
}
.swiper-container .swiper-slide {
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.box1 {
  padding-top: 15.833rem;
  /* background-image: url(../assets/images/bg/bg_01.jpg); */
  position: relative;
}
.phone-box1{
  padding-top: 30.833rem;
  position: relative;
}
.backimg img{
  width: auto !important;
}
.box2 {
  padding-top: 5.833rem;
  background-image: url(../assets/images/bg/bg_02.jpg);
}
.phone-box2{
  padding-top: 10.833rem;
  background-image: url(../assets/images/bg/bg_02.jpg);
}
.box3 {
  padding-top: 7.5rem;
  position: relative;
  overflow: hidden;
  /* background-image: url(../assets/images/bg/bg_03.jpg); */
}
.phone-box3 {
  padding-top: 12.5rem;
  position: relative;
  overflow: hidden;
}
.box4 {
  padding-top: 5.833rem;
  background-image: url(../assets/images/bg/bg_04.jpg);
}
.phone-box4{
  padding-top: 10.833rem;
  background-image: url(../assets/images/bg/bg_04.jpg);
}
.swiper-container .box5 {
  height: auto;
  background-color: #0e4158;
}
.wrap {
  margin: 0 auto;
  color: #fff;
  text-align: center;
}
.content1 {
  width: 34.167rem;
  height: auto;
  z-index: 20;
}
.phoneTop{
  margin-top: 36% !important;
}
.swiper-container3 {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
}
.mouse {
  height: 4.167rem;
  position: absolute;
  left: 50%;
  bottom: 1.667rem;
  transform: translateX(-50%);
  z-index: 20;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.mouse-icon{
  width: 1.25rem;
  height: 1.667rem;
  animation: banner_down1 2s infinite;
  transform: translateY(-50%);
}
.mouse-text{
  font-size: 0.75rem;
  font-family: ssb;
  color: #fff;
  margin-top: 0.833rem;
}
@keyframes banner_down1 {
  0% { transform: translateY(-50%); }
  100% { transform: translateY(0);}
}
.text1 {
  width: 100%;
  height: 4.417rem;
  background: url(@/assets/images/text.png) no-repeat center center;
  background-size: cover;
  z-index: 20;
  margin-bottom: 1.458rem;
}
/* 走进汇君 */
.content2 {
  width: 100%;
  height: 80%;
  overflow: hidden;
  padding: 0 5.417rem;
}
.mini-title1 {
  font-family: 'IT';
  color: #6ca8ba;
  font-weight: blod;
  font-size: 2rem;
  margin: 0 auto;
}
.title1 {
  position: absolute;
  top: 6.833rem;
  left: 50%;
  transform: translate(-50%, 0);
  font-size: 1.542rem;
  color: #fff;
  font-family: ssb;
}
.phone-title1{
  position: absolute;
  top: 11.833rem;
  left: 50%;
  transform: translate(-50%, 0);
  font-size: 16px;
  color: #fff;
  font-family: ssb;
}
.hj-text {
  margin-top: 1.458rem;
  font-size: 0.667rem;
  line-height: 2;
  width: 100%;
  /* height: 470px; */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 15;
  overflow: hidden;
  text-align: left;
  position: relative;
}

.detail {
  width: 10.833rem;
  height: auto;
  border-radius: 1.25rem;
  border: dashed 0.083rem #fff;
  text-align: center;
  line-height: 2.5rem;
  font-size: 0.75rem;
  position: absolute;
  left: 50%;
  bottom: 4.167rem;
  margin-left: -5.417rem;
  transition: all 0.6s ease;
  cursor: pointer;
}
.mouse-pad{
  bottom: 10rem !important;
}
.detail-pad{
  bottom:10rem !important;
  font-size: 14px;
}
.detail:hover {
  border: none;
  background-color: #31b5e2;
  cursor: pointer;
}
/* 解决方案 */
.box3-img {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  transition: all 0.6s ease;
  overflow: hidden;
  opacity: 0;
}
.box3-img.active{
  opacity: 0.89;
  transform: scale(1.05);
}
.box3-text {
  display: flex;
  justify-content: space-between;
  margin: 0 3.75rem;
}
.phoneBox3 {
  margin: 0 3.75rem;
}
.centent-l {
  width: 40rem;
  color: #fff;
  margin-top: 2.917rem;
}
.title-fixed {
  position: absolute;
  top: 0.75rem;
  left: 2.917rem;
  width: 40rem;
}
.top {
  margin-top: 4rem;
}
.banNav {
  position: relative;
  width: 26rem;
  height: 8.333rem;
  border: 0.083rem solid hsla(0, 0%, 100%, 0.2);
  display: flex;
  color: #fff;
}
.phoneBanNav {
  position: relative;
  width: 90%;
  height: auto;
  border: 0.083rem solid hsla(0, 0%, 100%, 0.2);
  display: flex;
  color: #fff;
}
.banNav_line {
  opacity: 0;
}

.banNav_line span:nth-child(1) {
  width: 0rem;
  height: 0.125rem;
  background: #fff;
  position: absolute;
  left: -0.042rem;
  top: -0.083rem;
}

.banNav_line span:nth-child(2) {
  width: 0.125rem;
  height: 0rem;
  background: #fff;
  position: absolute;
  right: -0.083rem;
  top: -0.083rem;
}

.banNav_line span:nth-child(3) {
  width: 0rem;
  height: 0.125rem;
  background: #fff;
  position: absolute;
  right: -0.083rem;
  bottom: -0.083rem;
}

.banNav_line span:nth-child(4) {
  width: 0.125rem;
  height: 0rem;
  background: #fff;
  position: absolute;
  left: -0.083rem;
  bottom: -0.083rem;
}

.banNav_line {
  opacity: 1;
}

.banNav_line.on span:nth-child(1),
.banNav_line.on span:nth-child(3) {
  animation: flash2 6s linear;
}

.banNav_line.on span:nth-child(2),
.banNav_line.on span:nth-child(4) {
  animation: flash1 6s linear;
}
@keyframes flash2 {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

@keyframes flash1 {
  0% {
    height: 0;
  }
  100% {
    height: 100%;
  }
}
/* 右上角 */
/* .rt{
  border-radius: 0 1.25rem 0 0;
} */
/* 左下角 */
/* .lb{
  border-radius: 0 0 0 1.25rem;
} */
.nav-num {
  width: 6.25rem;
  font-size: 2.792rem;
  margin: auto 0;
  padding-left: 1.6667rem;
  font-family: 'AE';
}
.nav-text {
  width: 100%;
  margin: auto 0;
  margin-left: 0.833rem;
  padding-right: 0.8333rem;
}
.text-z {
  font-size: 1.083rem;
  font-family: ssb;
  margin-top: 0.333rem;
  cursor: pointer;
}
/* 技术与产品 */
.drag{
  position: absolute;
  top: 0;
  width: 1.25rem;
  height: 19.16666rem;
  background: red;
  z-index: 90;
  cursor: pointer;
}
.dragLeft{
  left: 0;
}
.dragRight{
  right: 0;
}
.swiper-container2 {
  /* overflow: visible !important; */
  /* width: 36%; */
  /* 设置每个轮播的宽度，数值越小，两边显示的会越大 */
  height: 19.167rem;
}
.swiper-container4{
  height: auto;
} 
.swiper-container2 .swiper-slide {
  position: relative;
  cursor: pointer;
  overflow: hidden;
}
.swiper-container2 .swiper-slide .img-title {
  width: 15rem;
  height: 2.917rem;
  line-height: 2.917rem;
  padding-left: 2.083rem;
  overflow: hidden;
  white-space: nowrap;
  position: absolute;
  left: 0;
  top: 13.375rem;
  font-size: 0.75rem;
  border-left: 0.417rem solid #2096cb;
  background-image: linear-gradient(
    90deg,
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0)
  );
  transition: all 0.6s ease;
}
.swiper-container2 .swiper-slide:hover .img-title {
  background-image: linear-gradient(90deg, rgba(32, 150, 203, 1), rgba(255, 255, 255, 0));
  color: #fff;
}
.swiper-container2 .swiper-slide img{
  transition: all 0.6s ease;
}
.swiper-container2 .swiper-slide:hover img{
  transform: scale(1.1);
}
.swiper-container2 > .swiper-wrapper {
  width: 100%;
}
.swiper-container2 > .swiper-wrapper .swiper-slide {
  width: 36%;
  height: 100%;
  border-radius: 0.417rem;
  margin: 0 3.333rem;
}
.swiper-container2 > .swiper-wrapper .swiper-slide img {
  width: 100%;
  height: 100%;
}
.swiper-container4 .swiper-slide {
  position: relative;
  cursor: pointer;
  width: 100%;
  height: auto;
}
.product-title{
  position: absolute;
  left: 50%;
  top: -6rem;
  font-size: 16px;
  transform: translate(-50%,-50%);
  color: #fff;
}
.swiper-container4 .swiper-slide .img-title {
  width: 15rem;
  height: 2.917rem;
  line-height: 2.917rem;
  padding-left: 2.083rem;
  overflow: hidden;
  white-space: nowrap;
  position: absolute;
  left: 0;
  top: 70%;
  font-size: 0.75rem;
  border-left: 0.417rem solid #2096cb;
  background-image: linear-gradient(
    90deg,
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0)
  );
  transition: all 0.6s ease;
}
.swiper-container4 .swiper-slide:hover .img-title {
  background-image: linear-gradient(90deg, rgba(32, 150, 203, 1), rgba(255, 255, 255, 0));
  color: #fff;
}
.swiper-container4 .swiper-slide img{
  transition: all 0.6s ease;
}
.swiper-container4 .swiper-slide:hover img{
  transform: scale(1.1);
}
.swiper-container4 > .swiper-wrapper {
  width: 100%;
}
.swiper-container4 > .swiper-wrapper .swiper-slide img {
  width: 100%;
  height: auto !important;
}
.lb-foot {
  display: flex;
  justify-content: center;
  padding-top: 3.333rem;
  margin: 0 auto;
  color: #fff;
}
.phone-lb-foot {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 3.333rem;
  margin: 0 auto;
  color: #fff;
}
.lb-wrap {
  position: relative;
  width: 8.333rem;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s ease;
}
.lb-wrap-top{
  width: 11rem !important;
  margin-top: 0.2rem;
}
.lb-wrap:hover{
  color: #aac93b;
}
.lb-line {
  width: 100%;
  height: 0.042rem;
  background: #80b6bb;
  margin-bottom: 1.25rem;
}
.circle {
  position: absolute;
  left: 3.75rem;
  top: -0.375rem;
  width: 0.833333333rem;
  height: 0.83333333rem;
  padding: 0.16666666rem;
  border-radius: 50%;
  border: solid 0.041666666rem #fff;
}
.phoneCircle{
  position: absolute;
  left: -1rem;
  top: 40%;
  width: 0.833333rem;
  height: 0.83333333rem;
  padding: 0.16666666rem;
  border-radius: 50%;
  border: solid 0.042rem #fff;
}
.round {
  display: block;
  width: 0.4166666rem;
  height: 0.41666666rem;
  border-radius: 50%;
  padding: 0.20833rem;
  background: #fff;
}
.green {
  background: #aac93b !important;
}
</style>
