<template>
  <div class="head" 
  :class="[headShow ? '' : 'bg',!phoneSize ? '' : 'phone-head']">
    <div class="head-logo" @click="toHome()">
      <img src="@/assets/images/logo.png" alt="" v-if="headShow" />
      <img src="@/assets/images/logo-ys.png" alt="" v-else />
    </div>
    <!-- 手机端导航 -->
    <div class="phoneHead" v-if="menuShow">
      <el-menu
        :default-active="checkNav"
        class="el-menu-vertical-demo"
        background-color="rgba(14, 65, 88, 0.8)"
        text-color="#fff"
        active-text-color="#fff"
        router
        @select="close()"
      >
        <el-menu-item index="/">首页</el-menu-item>
        <el-menu-item index="huijun">走进汇君</el-menu-item>
        <el-menu-item index="" :route="{ path: 'solution', query: { id:0} }">解决方案</el-menu-item>
        <el-menu-item index="" :route="{ path: 'technologyproducts', query: { id:0} }">技术与产品</el-menu-item>
        <el-menu-item index="" :route="{ path: 'case', query: { id:1} }">典型案例</el-menu-item>
        <el-menu-item index="" :route="{ path: 'news', query: { id:1} }">新闻中心</el-menu-item>
        <el-menu-item index="callUs">联系我们</el-menu-item>
        <!-- <el-submenu index="solution"  @click.native="toSolution()">
          <template slot="title">解决方案</template>
          <el-menu-item index="solution" :route="{ path: 'solution', query: { id: 1 } }"
            >市政厨余/餐厨垃圾处理解决方案</el-menu-item
          >
          <el-menu-item index="solution" :route="{ path: 'solution', query: { id: 2 } }"
            >养殖企业环保解决方案</el-menu-item
          >
          <el-menu-item index="solution" :route="{ path: 'solution', query: { id: 3 } }"
            >县域集中式有机废弃物解决方案</el-menu-item
          >
        </el-submenu>
        <el-submenu index="technologyproducts" @click.native="toProduct()">
          <template slot="title">技术与产品</template>
          <el-menu-item
            index="technologyproducts"
            v-for="item in productData"
            :key="item.id"
            :route="{ path: 'technologyproducts', query: { id: item.cate_id } }"
            >{{ item.cate_title }}</el-menu-item
          >
        </el-submenu>
        <el-submenu index="case" @click.native="toCase()">
          <template slot="title">典型案例</template>
          <el-menu-item
            index="case"
            v-for="item in casesCate"
            :key="item.id"
            :route="{ path: 'case', query: { id: item.id } }"
            >{{ item.title }}</el-menu-item
          >
        </el-submenu>
        <el-submenu index="news" @click.native="toNews()">
          <template slot="title">新闻中心</template>
          <el-menu-item
            index="news"
            v-for="item in newsCate"
            :key="item.id"
            :route="{ path: 'news', query: { id: item.id } }"
          >
            {{ item.title }}
          </el-menu-item>
        </el-submenu>
        <el-submenu index="us" @click.native="toUs()">
          <template slot="title">联系我们</template>
          <el-menu-item index="callUs">联系方式</el-menu-item>
          <el-menu-item index="joinUs">加入我们</el-menu-item>
        </el-submenu> -->
      </el-menu>
    </div>
    <!-- PC端导航 -->
    <div class="head-nav" v-if="!phoneSize">
      <el-menu
        :default-active="checkNav"
        mode="horizontal"
        background-color="transparent"
        text-color="#fff"
        router
      >
        <el-menu-item index="/">首页</el-menu-item>
        <el-menu-item index="huijun">走进汇君</el-menu-item>
        <el-submenu index="solution" @click.native="toSolution()">
          <template slot="title">解决方案</template>
          <el-menu-item index="solution" :route="{ path: 'solution', query: { id: 1 } }"
            >市政厨余/餐厨垃圾处理解决方案</el-menu-item
          >
          <el-menu-item index="solution" :route="{ path: 'solution', query: { id: 2 } }"
            >养殖企业环保解决方案</el-menu-item
          >
          <el-menu-item index="solution" :route="{ path: 'solution', query: { id: 3 } }"
            >县域集中式有机废弃物解决方案</el-menu-item
          >
        </el-submenu>
        <el-submenu index="technologyproducts" @click.native="toProduct()">
          <template slot="title">技术与产品</template>
          <el-menu-item
            index="technologyproducts"
            v-for="item in productData"
            :key="item.id"
            :route="{ path: 'technologyproducts', query: { id: item.cate_id } }"
            >{{ item.cate_title }}</el-menu-item
          >
        </el-submenu>
        <el-submenu index="case" @click.native="toCase()">
          <template slot="title">典型案例</template>
          <el-menu-item
            index="case"
            v-for="item in casesCate"
            :key="item.id"
            :route="{ path: 'case', query: { id: item.id } }"
            >{{ item.title }}</el-menu-item
          >
        </el-submenu>
        <el-submenu index="news" @click.native="toNews()">
          <template slot="title">新闻中心</template>
          <el-menu-item
            index="news"
            v-for="item in newsCate"
            :key="item.id"
            :route="{ path: 'news', query: { id: item.id } }"
          >
            {{ item.title }}
          </el-menu-item>
        </el-submenu>
        <el-submenu index="us" @click.native="toUs()">
          <template slot="title">联系我们</template>
          <el-menu-item index="callUs">联系方式</el-menu-item>
          <el-menu-item index="joinUs">加入我们</el-menu-item>
        </el-submenu>
      </el-menu>
    </div>
    <div
      v-if="!phoneSize"
      ref="box"
      class="head-search"
      @click="searchShow = !searchShow"
    >
      <i class="el-icon-search"></i>
      <div class="search" v-if="searchShow">
        <el-input placeholder="KEYWORDS" prefix-icon="el-icon-search"> </el-input>
        <span class="btn">搜索</span>
      </div>
    </div>
    <div class="nav-menu" ref="menu" v-if="phoneSize" @click="menuShow = !menuShow">
      <i class="el-icon-menu" v-if="!menuShow"></i>
      <i class="el-icon-switch-button" v-else></i>
    </div>
  </div>
</template>
<script>
import { mapState,mapMutations } from "vuex";
export default {
  data () {
    return {
      searchShow: false, //搜索框显示
      menuShow: false, //菜单栏显示
      productData: [], //技术
      casesCate: [], //案例分类
      newsCate: [], //新闻分类
      // headShow: true, //头部样式变化
    }
  },
  // 监听
  mounted () {
    window.addEventListener('scroll', this.handleScroll) // 监听页面滚动
    // 点击空白弹框隐藏
    document.addEventListener("click", (e) => {
      if(this.searchShow){
        if (!this.$refs.box.contains(e.target)) this.searchShow = false;
      }
    });
  },
  created () {
    // this.$axios.get('/api/news/category').then((res) => {
      this.$axios.get('news/category').then((res) => {
      this.newsCate = res.data.data.list
    })
    // this.$axios.get('/api/cases/category').then((res) => {
      this.$axios.get('cases/category').then((res) => {
      this.casesCate = res.data.data.list
    })
    // this.$axios.get('/api/product/category').then((res) => {
      this.$axios.get('product/category').then((res) => {
      this.productData = res.data.data.list
    })
  },
  computed: {
    ...mapState(["phoneSize","headShow"]),
    checkNav () {
      const path = this.$route.path.replace('/', '')
      return path || '/'
    }
  },
  watch: {
    // 监听手机端是否结束，如果结束，菜单栏必须隐藏
    phoneSize (val) {
      if (val == false) this.menuShow = val
    }
  },
  methods: {
    // 判断是否手机端
    // ...mapMutations({ phoneWhite: 'ifPhone' }),
    // 手机端关闭菜单栏
    close () {
      this.menuShow = false
    },
    toHome () {
      this.$router.push('/')
    },
    // 获取页面滚动距离
    handleScroll () {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      if (scrollTop != 0) {
        this.showHead(false)
      } else {
        this.showHead(true)
      }
    },
    ...mapMutations({ showHead: 'ifHeadShow' }),
    toSolution(){
      this.menuShow = false
      this.$router.push({path:'solution',query:{id:0}})
    },
    toProduct(){
      this.menuShow = false
      this.$router.push({path:'technologyproducts',query:{id:0}})
    },
    toCase(){
      this.menuShow = false
      this.$router.push({path:'case',query:{id:1}})
    },
    toNews(){
      this.menuShow = false
      this.$router.push({path:'news',query:{id:1}})
    },
    toUs(){
      this.menuShow = false
      this.$router.push('callUs')
    }
  }
}
</script>

<style>
.el-menu--horizontal > .el-submenu .el-submenu__icon-arrow {
  margin-left: 0.3333rem !important;
  margin-top: -0.125rem !important;
}
.search .el-icon-search {
  line-height: 2.0833rem;
}
.bg {
  background: rgba(14, 65, 88, 0.8) !important;
}
.el-menu-item,
.el-submenu__title {
  /* width: 5.833rem !important; */
  padding: 0 0.8333rem !important;
  background-color: transparent !important;
}
.el-menu--popup {
  width: 5.833333rem !important;
  min-width: 0 !important;
  padding: 0.41666rem 0 !important;
  background-color: rgba(255, 255, 255, 0.8) !important;
  margin-top: 0;
}
.el-menu--popup .el-menu-item {
  display: block;
  color: #000 !important;
  text-align: center;
  font-size: 0.583rem !important;
  white-space: normal;
}
.el-menu--popup .el-menu-item:hover {
  color: #aac93b !important;
  background: rgba(14, 65, 88, 0.8) !important;
}
.el-menu--horizontal .el-menu .el-menu-item {
  height: auto;
  line-height: 1.5;
  padding: 0.4rem 0.41666rem !important;
}
.head-nav{
  width: 41.666666rem;
}
.head-nav ul li:hover {
  border-bottom: 0.125rem solid #aac93b !important;
}
.head-nav .el-submenu:hover .el-submenu__title {
  border-bottom: 0.125rem solid #aac93b !important;
}
/* 搜索框 */
.search {
  position: absolute;
  right: -5rem;
  top: 2.5rem;
  display: flex;
  border-radius: 0.2083rem;
  overflow: hidden;
  width: 16.4583rem;
  font-size: 0.5833rem;
}
.el-input--prefix {
  width: 11.4583rem;
}
.search .el-input__inner {
  background-color: rgba(14, 65, 88, 0.8);
  border-radius: 0;
  border: none;
  height: 2.0833rem;
  line-height: 2.0833rem;
  font-size: 0.5833rem;
}
.el-input--prefix .el-input__inner {
  padding-left: 2.5rem;
}
.search .btn {
  display: inline-block;
  width: 5rem;
  height: 2.0833rem;
  text-align: center;
  line-height: 2.0833rem;
  color: #fff;
  background-color: #aac93b;
  font-size: 0.5833rem;
}
</style>
<style scoped>
img {
  width: 100%;
  height: 100%;
}
.phone-head{
  height: 5rem !important;
}
.head {
  position: fixed;
  width: 100%;
  height: 4rem;
  top: 0;
  left: 0;
  z-index: 90;
  display: flex;
  border-bottom: 0.042rem solid #e6e6e6;
}
.head-logo {
  width: 18.75rem;
  height: 2.5rem;
  margin: 1.042rem 7.5rem 0 3.75rem;
  cursor: pointer;
}
.phoneHead {
  width: 100%;
  position: absolute;
  left: 0;
  top: 4rem;
  padding-left: 3.75rem;
  background-color: #0e4158;
}
.head-search {
  width: 1.4583rem;
  height: 1.042rem;
  margin: auto 0;
  margin-left: 1.6667rem;
  position: relative;
  cursor: pointer;
}
/* 导航菜单 */
.nav-menu {
  width: 3.2rem;
  height: 3.2rem;
  margin: auto 0;
  position: absolute;
  right: 2rem;
  top: 50%;
  transform: translateY(-1.6rem);
  color: #fff;
  cursor: pointer;
}
.nav-menu i{
  font-size: 3.2rem;
}
/* 样式覆盖 */
.head-nav >>> .el-menu-item {
  width: 5.833rem;
  height: 3.917rem;
  line-height: 3.917rem;
  font-size: 0.708rem;
  text-align: center;
}
.head-nav >>> .el-submenu {
  width: 5.833rem;
  height: 3.917rem;
  line-height: 3.917rem;
  font-size: 0.708rem;
  text-align: center;
}
.head-nav >>> .el-menu--horizontal .el-submenu .el-submenu__title {
  height: 3.917rem;
  line-height: 3.917rem;
  font-size: 0.708rem;
}
.head-nav >>> .el-menu.el-menu--horizontal {
  border-bottom: solid 0 #e6e6e6;
}
.head-nav >>> .el-menu--horizontal .el-menu-item.is-active {
  border-bottom: 0.125rem solid #aac93b;
  color: #fff;
}
.head-search >>> .el-icon-search {
  color: #fff;
  font-size: 1.042rem;
}
.head-nav >>> .el-menu--horizontal .el-submenu.is-active .el-submenu__title {
  border-bottom: 0.125rem solid #aac93b;
  color: #fff;
}
.head-nav >>> .el-menu--popup {
  /* margin-top: -0.208rem; */
  width: 5.833rem !important;
}
.head-nav >>> .el-menu--popup .el-menu-item {
  width: 5.833rem !important;
}
.phoneHead >>> .el-submenu .el-menu {
  padding-left: 1rem;
}
</style>