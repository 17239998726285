import Vue from "vue";
//引入Vuex
import Vuex from "vuex";
// 应用 Vuex 插件
Vue.use(Vuex);

//准备 mutations 用于操作数据（state）
const mutations = {
  ifPhone(state, value) {
    if (value <= 840) {
      state.phoneSize = true;
    } else {
      state.phoneSize = false;
    }
  },
  ifHeadShow(state,value){
    state.headShow = value
  }
};
//准备 state 用于存储数据
const state = {
  headShow:"true", //头部背景true不变
  phoneSize: "false", //是否手机端
};

//创建并暴露 store
export default new Vuex.Store({
  mutations,
  state,
});
