<template>
  <div class="container">
    <div class="main">
      <div class="main-bg"></div>
      <div class="main-text">
        <p class="text-title1" style="margin: 0.417rem 0">走进汇君</p>
        <p class="text-title1-en">INTO THE HUIJUN</p>
      </div>
    </div>
    <div class="nav-head" v-if="!phoneSize">
      <div class="nav-title"></div>
      <div class="nav-text">
        <div style="margin-right: 0.625rem; width: 1.042rem; height: 1.042rem">
          <img src="@/assets/images/icon/icon-house.png" alt="" />
        </div>
        <div class="right-nav">
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>走进汇君</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
      </div>
    </div>
    <div :class="phoneSize ? 'phoneAbout' : 'about'">
      <div :class="phoneSize ? 'phoneText' : 'text'">
        <div class="wow fadeIn" data-wow-duration="2s" data-wow-iteration="1">
          <h1 class="text-title left">关于汇君</h1>
          <p
            class="left"
            style="font-size: 1.25rem; font-weight: 500"
            v-if="!phoneSize"
          >
            做中国生物质能源资源化利用事业的践行者
          </p>
          <p
            class="left"
            style="font-size: 15px; font-weight: 500"
            v-if="phoneSize"
          >
            做中国生物质能源资源化利用事业的践行者
          </p>
          <div style="margin-top: 2.083rem">
            <div
              style="
                display: inline-block;
                width: 70%;
                height: 0.042rem;
                background: #e2e3e3;
                margin: 0.417rem 0;
              "
            ></div>
            <i
              style="
                font-size: 1.833rem;
                margin-left: 0.417rem;
                margin-right: 0.417rem;
                color: #a9abab;
                font-family: CL;
              "
              >SINCE</i
            >
            <i style="font-size: 1.833rem; font-family: ssb; color: #35b5e3"
              >2013</i
            >
          </div>
        </div>
        <div
          class="text-hj left wow fadeIn"
          :class="phoneSize ? 'size' : ''"
          data-wow-duration="5s"
          swiper-animate-delay="2s"
          data-wow-iteration="1"
          v-html="about.content"
        ></div>
      </div>
      <div
        class="image wow fadeInRight"
        data-wow-duration="1s"
        data-wow-delay="0.5s"
        data-wow-iteration="1"
        v-if="!phoneSize"
      >
        <img :src="$pregImg + about.thumb" alt="" />
      </div>
    </div>
    <!-- 发展历程 -->
    <div class="course">
      <div class="course-title">
        <p class="text-title-en" :class="phoneSize ? 'size' : ''">
          DEVELOPMENT COURSE
        </p>
        <p class="text-title">发展历程</p>
      </div>
      <div class="course-l" v-for="(item, index) in rightYear" :key="index">
        <div style="font-size: 2.083rem; color: #fff; font-weight: 600">
          {{ item.year }}
        </div>
        <div
          style="
            width: 3.167rem;
            height: 0.417rem;
            background-color: #aac93b;
            margin: 0.833rem 0;
          "
        ></div>
        <div
          class="course-text"
          :class="phoneSize ? 'size' : ''"
          v-html="item.content"
        ></div>
      </div>
      <div class="course-r">
        <div class="year">
          <!-- <div
            v-for="(item, index) in product"
            :key="index"
            @mouseenter="toYear(item.year)"
            :class="[year == item.year ? 'yearBor' : '']"
          >
            {{ item.year }}
          </div> -->
          <div class="swiper-container1">
            <div class="swiper-wrapper">
              <div
                class="swiper-slide"
                :class="phoneSize ? 'size' : ''"
                v-for="(item, index) in product"
                :key="index"
              >
                {{ item.year }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 企业文化 -->
    <div style="padding-top: 4.583rem" v-if="!phoneSize">
      <div style="margin: 0 auto; text-align: center">
        <p class="text-title">企业文化</p>
        <p class="text-title-en" style="color: #b8b8b8">ENTERPRISE CULTURE</p>
      </div>
      <div class="corporate-culture">
        <div class="mission wrap" :class="[mission ? 'high' : 'short']">
          <div class="wrap-image">
            <img :src="$pregImg + culturel.thumb" alt="" />
          </div>
          <div v-if="mission">
            <div
              style="font-size: 1.25rem; font-weight: 500; margin-top: 6.25rem"
            >
              {{ culturel.title }}
            </div>
            <div
              style="
                width: 3.167rem;
                height: 0.417rem;
                background-color: #aac93b;
                margin: 0.833rem 0;
              "
            ></div>
            <div class="sign">{{ culturel.title_en }}</div>
            <div
              class="culturel-text"
              style="color: #fff"
              v-html="culturel.content"
            ></div>
          </div>
          <div class="culture-icon" v-if="!mission" v-on:mouseenter="tag1()">
            <div class="icon-image">
              <img src="@/assets/images/huijun/icon_1.png" alt="" />
            </div>
            <p>使命</p>
          </div>
        </div>
        <div class="vision wrap" :class="[vision ? 'high' : 'short']">
          <div class="wrap-image">
            <img :src="$pregImg + culturec.thumb" alt="" />
          </div>
          <div v-if="vision">
            <div
              style="font-size: 1.25rem; font-weight: 500; margin-top: 6.25rem"
            >
              {{ culturec.title }}
            </div>
            <div
              style="
                width: 3.167rem;
                height: 0.417rem;
                background-color: #aac93b;
                margin: 0.833rem 0;
              "
            ></div>
            <div class="sign">{{ culturec.title_en }}</div>
            <div
              class="culturel-text"
              style="color: #fff"
              v-html="culturec.content"
            ></div>
          </div>
          <div
            class="culture-icon vision-icon"
            v-if="!vision"
            v-on:mouseenter="tag2()"
          >
            <div class="icon-image">
              <img src="@/assets/images/huijun/icon_1.png" alt="" />
            </div>
            <p>愿景</p>
          </div>
        </div>
        <div class="worth wrap" :class="[worth ? 'high' : 'short']">
          <div class="wrap-image">
            <img :src="$pregImg + culturer.thumb" alt="" />
          </div>
          <div v-if="worth">
            <div
              style="font-size: 1.25rem; font-weight: 500; margin-top: 6.25rem"
            >
              {{ culturer.title }}
            </div>
            <div
              style="
                width: 3.167rem;
                height: 0.417rem;
                background-color: #aac93b;
                margin: 0.833rem 0;
              "
            ></div>
            <div class="sign">{{ culturer.title_en }}</div>
            <div
              class="culturel-text"
              style="color: #fff"
              v-html="culturer.content"
            ></div>
          </div>
          <div
            class="culture-icon worth-icon"
            v-if="!worth"
            v-on:mouseenter="tag3()"
          >
            <div class="icon-image">
              <img src="@/assets/images/huijun/icon_1.png" alt="" />
            </div>
            <p>价值感</p>
          </div>
        </div>
      </div>
    </div>
    <!-- 企业文化手机端 -->
    <div style="padding-top: 4.583rem" v-if="phoneSize">
      <div style="margin: 0 auto; text-align: center">
        <p class="text-title">企业文化</p>
        <p class="text-title-en" style="color: #b8b8b8; font-size: 14px">
          ENTERPRISE CULTURE
        </p>
      </div>
      <div class="corporate-culture-phone">
        <div
          class="mission wrap-phone wow fadeIn"
          data-wow-duration="3s"
          swiper-animate-delay="1s"
          data-wow-iteration="1"
        >
          <div class="wrap-image-phone">
            <img :src="$pregImg + culturel.thumb" alt="" />
          </div>
          <div>
            <div style="font-size: 15px; font-weight: 500; margin-top: 6.25rem">
              {{ culturel.title }}
            </div>
            <div
              style="
                width: 3.167rem;
                height: 0.417rem;
                background-color: #aac93b;
                margin: 0.833rem 0;
              "
            ></div>
            <div class="sign">{{ culturel.title_en }}</div>
            <div
              class="phoneCulturel-text size2"
              style="color: #fff"
              v-html="culturel.content"
            ></div>
          </div>
        </div>
        <div
          class="vision wrap-phone wow fadeIn"
          data-wow-duration="5s"
          swiper-animate-delay="2s"
          data-wow-iteration="1"
        >
          <div class="wrap-image-phone">
            <img :src="$pregImg + culturec.thumb" alt="" />
          </div>
          <div>
            <div style="font-size: 14px; font-weight: 500; margin-top: 6.25rem">
              {{ culturec.title }}
            </div>
            <div
              style="
                width: 3.167rem;
                height: 0.417rem;
                background-color: #aac93b;
                margin: 0.833rem 0;
              "
            ></div>
            <div class="sign">{{ culturec.title_en }}</div>
            <div
              class="phoneCulturel-text size"
              style="color: #fff"
              v-html="culturec.content"
            ></div>
          </div>
        </div>
        <div
          class="worth wrap-phone wow fadeIn"
          data-wow-duration="5s"
          swiper-animate-delay="2s"
          data-wow-iteration="1"
        >
          <div class="wrap-image-phone">
            <img :src="$pregImg + culturer.thumb" alt="" />
          </div>
          <div>
            <div style="font-size: 14px; font-weight: 500; margin-top: 6.25rem">
              {{ culturer.title }}
            </div>
            <div
              style="
                width: 3.167rem;
                height: 0.417rem;
                background-color: #aac93b;
                margin: 0.833rem 0;
              "
            ></div>
            <div class="sign">{{ culturer.title_en }}</div>
            <div
              class="phoneCulturel-text size"
              style="color: #fff"
              v-html="culturer.content"
            ></div>
          </div>
        </div>
      </div>
    </div>
    <!-- 荣誉资质 -->
    <div style="padding-top: 4.583rem">
      <div style="margin: 0 auto; text-align: center">
        <p class="text-title">荣誉资质</p>
        <p
          class="text-title-en"
          style="color: #b8b8b8"
          :class="phoneSize ? 'size' : ''"
        >
          HONORARY CERTIFICATE
        </p>
      </div>
      <div style="padding-top: 2.5rem">
        <div class="swiper-container">
          <div class="swiper-wrapper">
            <div
              class="swiper-slide"
              v-for="(item, index) in honor"
              :key="index"
              @click="show(item.thumb)"
            >
              <div class="ry-image">
                <img :src="$pregImg + item.thumb" />
              </div>
              <p :class="phoneSize ? 'mini-size' : ''">{{ item.title }}</p>
            </div>
          </div>
          <div
            class="swiper-button-prev btn swiper-button-white"
            v-if="!phoneSize"
          ></div>
          <div
            class="swiper-button-next btn swiper-button-white"
            v-if="!phoneSize"
          ></div>
          <div class="pagination"></div>
        </div>
      </div>
    </div>
    <!-- 弹框 -->
    <div v-if="showImage">
      <div class="mask" @click="showImage = false">
        <div class="close" @click="showImage = false">
          <img src="@/assets/images/us/close_03.jpg" alt="" />
        </div>
      </div>
      <div class="pop">
        <img :src="$pregImg + url" />
      </div>
    </div>
    <foot></foot>
  </div>
</template>
<script>
let vm = null;
import { WOW } from "wowjs";
import { mapState } from "vuex";
import Swiper from "swiper";
export default {
  data() {
    return {
      showImage: false, //轮播图预览
      url: "", //轮播图预览地址
      mission: false,
      vision: true,
      worth: false,
      about: {}, //关于汇君
      product: [], //技术与产品
      year: 2017,
      isIndex: 3,
      rightYear: [], //渲染的年份信息
      culturel: [], //文化
      culturec: [], //文化
      culturer: [], //文化
      honor: {}, //荣誉资质
    };
  },
  mounted() {
    // this.$axios.get("/api/about/index").then((res) => {
    // 获取首页数据
    this.$axios.get("about/index").then((res) => {
      // 获取首页数据
      this.about = res.data.data;
    });
    // this.$axios.get("/api/about/development").then((res) => {
    // 获取产品历程数据
    this.$axios.get("about/development").then((res) => {
      // 获取产品历程数据
      this.product = res.data.data.list;
      console.log(this.product);
      this.toYear(this.year);
    });
    // this.$axios.get("/api/about/culture").then((res) => {
    // 获取文化
    this.$axios.get("about/culture").then((res) => {
      // 获取文化
      this.culturel = res.data.data.list[0];
      this.culturec = res.data.data.list[1];
      this.culturer = res.data.data.list[2];
    });
    // this.$axios.get("/api/about/honor?page=1&&pagesize=50").then((res) => {
    // 获取荣誉数据
    this.$axios.get("about/honor?page=1&&pagesize=50").then((res) => {
      // 获取荣誉数据
      this.honor = res.data.data.list;
      this.$nextTick(() => {
        this.bannerPoint();
      });
    });
  },
  watch: {
    about() {
      this.$nextTick(() => {
        // 在dom渲染完后,再执行动画
        let wow = new WOW({
          boxClass: "wow", //需要执行动画的元素的 class
          animateClass: "animated", //animation.css 动画的 class
          offset: 0, //距离可视区域多少开始执行动画
          mobile: true, //是否在移动设备上执行动画
          live: true, //异步加载的内容是否有效
        });
        wow.init();
      });
    },
    // 监听年份滚动
    isIndex: function (val) {
      const year = 2021 - val;
      this.toYear(year);
    },
  },
  computed: {
    ...mapState(["phoneSize"]),
  },
  methods: {
    bannerPoint() {
      vm = this;
      var mySwiper = new Swiper(".swiper-container", {
        loop: true, // 循环模式选项
        slidesPerView: "auto", // 默认一个屏幕显示几张图，必须auto！要不轮播到最后一张图时，右侧会是空白，很丑！
        loopedSlides: 10,
        centeredSlides: true, // 每屏，居中显示
        pagination: {
          el: ".pagination",
          clickable: true,
        },
        autoplay: {
          delay: 1000, // 多长时间进行一次自动切换
          disableOnInteraction: false, // 用户点击一次后是否取消自动切换 默认true
        },
        speed: 1000,
        observer: true,
        observeParents: true,
        observeSlideChildren: true,
        initialSlide: 2, //默认第二个
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      });
      new Swiper(".swiper-container1", {
        direction: "vertical",
        mousewheel: true,
        observer: true,
        observeParents: true,
        observeSlideChildren: true,
        initialSlide: 5, //默认2017年
        on: {
          slideChange: function () {
            vm.isIndex = this.realIndex - 1;
          },
        },
      });
      //鼠标覆盖停止自动切换
      mySwiper.el.onmouseover = function () {
        mySwiper.autoplay.stop();
      };
      //鼠标离开开始自动切换
      mySwiper.el.onmouseout = function () {
        mySwiper.autoplay.start();
      };
    },
    tag1() {
      this.mission = true;
      this.vision = false;
      this.worth = false;
    },
    tag2() {
      this.mission = false;
      this.vision = true;
      this.worth = false;
    },
    tag3() {
      this.mission = false;
      this.vision = false;
      this.worth = true;
    },
    toYear(n) {
      this.rightYear = this.product.filter((item) => {
        return item.year == n;
      });
    },
    show(n) {
      this.showImage = true;
      this.url = n;
    },
  },
};
</script>
<style scoped>
.mini-size {
  font-size: 13px !important;
}
.size {
  font-size: 14px !important;
}
.btn {
  width: 2.4rem;
  height: 2.4rem;
  border-radius: 50%;
  text-align: center;
  line-height: 2.4rem;
  background-color: rgba(14, 65, 88, 0.8);
}
.text-title1 {
  font-size: 2.667rem;
  font-family: ssb;
}
.text-title1-en {
  font-size: 1.5rem;
  font-family: Arial, Helvetica, sans-serif;
}
.text-title {
  font-size: 2.292rem;
  font-family: ssb;
}
.text-title-en {
  font-size: 1.417rem;
  font-family: Arial, Helvetica, sans-serif;
}
img {
  width: 100%;
  height: 100%;
}
/* 走进汇君 */
.main {
  width: 100%;
  padding-top: 0.042rem;
  height: 36.667rem;
  overflow: hidden;
  position: relative;
}
.main-bg {
  position: absolute;
  width: 100%;
  height: 100%;
  background: url(@/assets/images/huijun/huijun-bg.jpg) no-repeat;
  background-size: 100% 100%;
  animation: zoom_big2 16s ease-out infinite;
  z-index: -1;
}
@keyframes zoom_big2 {
  0% {
    transform: scale(1);
  }
  60% {
    transform: scale(1.05);
  }
  95% {
    transform: scale(1);
  }
  100% {
    transform: scale(1);
  }
}
.main-text {
  margin: 15.417rem 0 0 3.333rem;
  color: #fff;
}
/* 分类导航 */
.nav-head {
  position: relative;
}
.nav-title {
  width: 54.167rem;
  height: 6.25rem;
  background-image: linear-gradient(to right, #72caed, #26b1e0);
  border-radius: 0 3.75rem 0 0;
  position: absolute;
  left: 0;
  top: -3.75rem;
  z-index: 11;
}
.nav-text {
  width: 41.667rem;
  height: 6.25rem;
  background-image: linear-gradient(to right, #e6eeca, #a6c142);
  border-radius: 0 0 0 2.083rem;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.right-nav {
  margin-right: 3.333rem;
}
/* 关于汇君 */
.about {
  display: flex;
  justify-content: space-between;
  padding-top: 7.917rem;
  padding-bottom: 2.083rem;
}
.phoneAbout {
  padding-top: 4rem;
  padding-bottom: 2.083rem;
}
.left {
  margin: 0 3.333rem;
}
.text {
  width: 37.5rem;
  margin: 4.583rem 0;
}
.phoneText {
  width: 100%;
  margin: 4.583rem 0;
}
.text-hj {
  padding-top: 2.083rem;
}
.text-hj /deep/ p {
  line-height: 2 !important;
  font-size: 0.6667rem !important;
}
.size /deep/ p {
  font-size: 13px !important;
}
.size2 /deep/ p {
  font-size: 13px !important;
}
.image {
  width: 35.833rem;
  /* height: 40.417rem; */
}
/* 发展历程 */
.course {
  position: relative;
  display: flex;
  height: 37.917rem;
}
.course-title {
  position: absolute;
  right: 6.667rem;
  top: 4.167rem;
  color: #fff;
  text-align: right;
}
.course-l {
  width: 59.167rem;
  padding: 13.833rem 7.5rem 0;
  background: url(@/assets/images/huijun/course-bg.jpg) no-repeat center;
  background-size: cover;
}
.course-l .course-text {
  padding-top: 1.25rem;
  color: #fff;
  line-height: 2;
  font-size: 0.667rem;
}
.course-text /deep/p {
  color: #fff;
  line-height: 2 !important;
  font-size: 0.667rem;
}
.course-r {
  width: 20.833rem;
  padding-top: 9.6rem;
  background-color: #a6c142;
}
.year {
  width: 7.083rem;
  height: 27rem;
  margin: 0 auto;
  padding-top: 13rem;
  color: #fff;
  font-size: 1rem;
  text-align: center;
  overflow: hidden;
}
.swiper-container1 {
  border-top: 2px solid #fff !important;
  border-bottom: 2px solid #fff !important;
}
.swiper-container1 .swiper-wrapper {
  width: 7.083rem;
  height: 3.333rem;
}
.year .swiper-container1 .swiper-slide {
  width: 100%;
  height: 3.333rem;
  text-align: center;
  line-height: 3.333rem;
  cursor: pointer;
}
.yearBor {
  transition: all 0.6s ease;
  border-top: 2px solid #fff !important;
  border-bottom: 2px solid #fff !important;
}
.white-line {
  width: 100%;
  height: 0.083rem;
  background-color: #fff;
}
/* 企业文化 */
.corporate-culture {
  /* position: relative; */
  margin-top: 3.333rem;
  height: 38.75rem;
  display: flex;
}
.corporate-culture-phone {
  margin-top: 3.333rem;
  display: flex;
  flex-direction: column;
}
.wrap-phone {
  width: 100%;
  height: 38.75rem;
  margin-bottom: 0.833rem;
  padding: 0 2.5rem;
  position: relative;
  overflow: hidden;
  transition: all 0.6s ease;
}
.wrap {
  height: 38.75rem;
  padding: 0 2.5rem;
  position: relative;
  overflow: hidden;
  transition: all 0.6s ease;
}
.wrap-image {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
}
.wrap-image-phone {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
}
.wrap-image img {
  width: auto;
  height: 38.75rem;
}
.wrap-image-phone img {
  width: 100%;
  height: 38.75rem;
}
.sign {
  width: 100%;
  text-align: center;
  margin: 3.75rem 0 6.25rem 0;
  font-size: 7.083rem;
  color: #c6c8c9;
  color: rgb(199, 200, 202, 0.3);
}
.culturel-text {
  width: 30rem;
}
.phoneCulturel-text {
  width: 100%;
}
.culturel-text /deep/ p {
  font-size: 0.667rem;
  line-height: 2 !important;
}
.culture-icon {
  padding-top: 17.333rem;
  margin: 0 auto;
  width: 6.042rem;
  height: auto;
  text-align: center;
}
.high {
  width: 34.167rem;
}
.short {
  width: 22.917rem;
}
.icon-image {
  width: 6.042rem;
  height: 6.042rem;
  margin-bottom: 0.417rem;
}
.culture-icon p {
  font-size: 0.75rem;
  color: #fff;
}
/* 荣誉资质 */
.ry-image {
  width: 100%;
  height: 13.917rem;
  padding: auto 0;
  position: relative;
  margin-bottom: 1rem;
}
.ry-image img {
  position: absolute;
  transition: all 0.6s ease;
  height: auto !important;
  top: 1.667rem;
  left: 0;
  margin-top: 50%;
  transform: translateY(-50%);
}
.swiper-container {
  cursor: pointer;
  margin-bottom: 4rem;
}
.swiper-container .swiper-wrapper {
  width: 15.417rem;
}
.swiper-container .swiper-slide {
  justify-content: center;
  width: 10.417rem;
  height: auto;
  margin: 0 1.667rem 2rem;
}
.swiper-container .swiper-slide p {
  width: 100%;
  font-size: 0.833rem;
  text-align: center;
}
.swiper-container .pagination {
  position: absolute;
  z-index: 20;
  bottom: 0;
  width: 100%;
  text-align: center;
}
.swiper-container .swiper-pagination-switch {
  display: inline-block;
  width: 0.333rem;
  height: 0.333rem;
  border-radius: 0.333rem;
  background: #555;
  margin: 0 0.208rem;
  opacity: 0.8;
  border: 0.042rem solid #fff;
  cursor: pointer;
}
.swiper-container .swiper-active-switch {
  background: #fff;
}
/* 弹框 */
.mask {
  background-color: #000;
  opacity: 0.8;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
}
.pop {
  position: fixed;
  width: auto;
  height: auto;
  border-radius: 1.25rem;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
}
.close {
  position: absolute;
  top: 1.25rem;
  right: 1.25rem;
  width: 2.208rem;
  height: 2.208rem;
  border-radius: 50%;
  overflow: hidden;
}
/* 样式覆盖 */
.nav-head >>> .el-breadcrumb {
  font-size: 0.583rem;
}
.nav-head >>> .el-breadcrumb__inner.is-link {
  color: #fff;
}
.nav-head >>> .el-breadcrumb__separator {
  color: #fff;
}
.nav-head >>> .el-breadcrumb__item .el-breadcrumb__inner {
  color: #fff;
}
</style>