<template>
  <div>
    <div class="main">
      <div class="main-bg"></div>
      <div class="main-text">
        <p class="text-title" style="margin: 0.417rem 0">技术与产品</p>
        <p class="text-title-en">TECHNOLOGY AND PRODUCTS</p>
      </div>
    </div>
    <div class="nav-head" v-if="!phoneSize">
      <div class="nav-title">
        <el-menu
          :default-active="activeIndex"
          class="el-menu-demo"
          mode="horizontal"
          text-color="#fff"
          background-color="transparent"
          active-text-color="#fff"
        >
          <el-menu-item
            :index="item.cate_id"
            v-for="item in productData"
            :key="item.cate_id"
            @click="changeCate(item.cate_id)"
          >
            {{ item.cate_title }}
          </el-menu-item>
        </el-menu>
      </div>
      <div class="nav-text">
        <div style="margin-right: 0.625rem; width: 1.042rem; height: 1.042rem">
          <img src="@/assets/images/icon/icon-house.png" alt="" />
        </div>
        <div class="right-nav">
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item v-if="cateId == 1">前端处理</el-breadcrumb-item>
            <el-breadcrumb-item v-else-if="cateId == 2">厌氧发酵</el-breadcrumb-item>
            <el-breadcrumb-item v-else-if="cateId == 3">好氧发酵</el-breadcrumb-item>
            <el-breadcrumb-item v-else-if="cateId == 4">除臭工艺</el-breadcrumb-item>
            <el-breadcrumb-item v-else-if="cateId == 5">净化提纯</el-breadcrumb-item>
            <el-breadcrumb-item v-else-if="cateId == 6">污水处理</el-breadcrumb-item>
            <el-breadcrumb-item v-else-if="cateId == 7">有机肥产品</el-breadcrumb-item>
            <el-breadcrumb-item v-else>有机废弃物智能收运系统</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
      </div>
    </div>
    <div class="nav-two" v-if="phoneSize">
      <div
        class="two-wrap"
        v-for="item in productData"
        :key="item.cate_id"
        @click="changeCate(item.cate_id)"
      >
        <div class="two-text">{{ item.cate_title }}</div>
      </div>
    </div>
    <!-- 项目 -->
    <div>
      <div class="project">
        <div class="project-main">
          <div class="project-img" :class="!phoneSize ?'':'full1'">
            <img :src="$pregImg + productDetail.thumb" alt="" />
          </div>
          <div class="project-text" :class="!phoneSize ?'':'full1'">
            <h2 style="font-size:1.333rem" :class="phoneSize ?'big-size':''">{{ productDetail.title }}</h2>
            <div style="line-height: 2; font-size: 0.708rem; margin: 2.083rem 0" :class="phoneSize ?'size':''">
              {{ productDetail.description }}
            </div>
          </div>
        </div>
      </div>
      <div class="project-list">
        <div class="list" :class="[productDetail.content2 ? '':'full',!phoneSize ?'':'full']" v-if="productDetail.content1">
          <div class="list-title" :class="phoneSize ?'big-size':''">
            {{productDetail.content_title1}}
            <div class="line"></div>
          </div>
          <div class="list-main" :class="phoneSize ?'size':''" v-html="productDetail.content1"></div>
        </div>
        <div class="list" v-if="productDetail.content2"  :class="!phoneSize ?'':'full'">
          <div class="list-title" :class="phoneSize ?'big-size':''">
            {{productDetail.content_title2}}
            <div class="line"></div>
          </div>
          <div class="list-main" :class="phoneSize ?'size':''" v-html="productDetail.content2"></div>
        </div>
      </div>
      <div class="project mar"  v-if="productDetail.content5">
        <div class="tableImage" :class="phoneSize ?'tablePhoneImage':''" v-html="productDetail.content5"></div>
      </div>
      <div style="width: 100%; height: 2.5rem"></div>
    </div>
    <foot></foot>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  data () {
    return {
      activeIndex: '1',
      id: '',
      cateId: 1,
      productData: [], //标题列表
      productDetail: {}, //技术列表
    };
  },
  watch: {
    '$route': function () {
      this.activeIndex = this.$route.query.cate_id
      this.cateId = this.$route.query.cate_id
      this.queryList()
    }
  },
  created () {
    this.id = this.$route.query.id
    this.queryList()
    // this.$axios.get('/api/product/category').then((res) => {
      this.$axios.get('product/category').then((res) => {
      this.productData = res.data.data.list
    })
  },
  computed: {
    ...mapState(["phoneSize"]),
  },
  methods: {
    changeCate (id) {
      this.$router.push({ path: 'technologyproducts', query: { id: id } })
    },
    // 更新工作数据
    queryList () {
      // this.$axios.get('/api/product/detail?id=' + this.id).then((res) => {
        this.$axios.get('product/detail?id=' + this.id).then((res) => {
        this.productDetail = res.data.data
        this.activeIndex = this.productDetail.cate_id
        console.log(this.productDetail);
      })
    },
  }
}
</script>
<style scoped>
  .big-size{
    font-size: 16px !important;
  }
  .size{
    font-size: 14px !important;
  }
  .size /deep/p{
    font-size: 14px !important;
  }
.el-menu-item:hover{
  color: #fff !important;
}
.el-menu-item{
  color: rgba(255, 255, 255, 0.7) !important;
}
.text-title {
  font-size: 2.667rem;
  font-family: ssb;
}
.text-title-en {
  font-size: 1.5rem;
  font-family: Arial, Helvetica, sans-serif;
}
img {
  width: 100%;
  height: 100%;
}
/* 走进汇君 */
.main{
  width: 100%;
  padding-top: 0.042rem;
  height: 36.667rem;
  overflow: hidden;
  position: relative;
}
.main-bg{
  position: absolute;
  width: 100%;
  height: 100%;
  background: url(@/assets/images/products/product.jpg) no-repeat center;
  background-size: cover;
  animation: zoom_big2 16s ease-out infinite;
  z-index: -1;
}
@keyframes zoom_big2 {
  0%{
    transform: scale(1);
  }
  60% {
    transform: scale(1.05);
  }
  95% {
    transform: scale(1);
  }
  100% {
    transform: scale(1);
  }
}
.main-text {
  margin: 15.417rem 0 0 3.333rem;
  color: #fff;
}

/* 分类导航 */
.nav-head {
  position: relative;
  margin-bottom: 9.167rem;
}
.nav-title {
  width: 54.167rem;
  height: 6.667rem;
  background-image: linear-gradient(to right, #72caed, #26b1e0);
  border-radius: 0 3.75rem 0 0;
  position: absolute;
  left: 0;
  top: -3.75rem;
  z-index: 11;
  padding: 0 0 0 4.167rem;
}
.nav-text {
  width: 41.667rem;
  height: 6.25rem;
  background-image: linear-gradient(to right, #e6eeca, #a6c142);
  border-radius: 0 0 0 2.083rem;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.right-nav {
  margin-right: 3.333rem;
}
/* 项目 */
.project {
  width: 100%;
  padding: 0 3.75rem;
  margin-top: 2.292rem;
}
.project-main {
  width: 100%;
  height: auto;
  flex-wrap: wrap;
  border-radius: 0.625rem;
  overflow: hidden;
  display: flex;
  margin-bottom: 2.292rem;
}
.project-img {
  width: 49.6%;
  height: 23.125rem;
}
.project-text {
  width: 50.4%;
  height: 23.125rem;
  padding: 4.583rem 2.917rem 0 2.917rem;
  color: #fff;
  background-color: #27b2e1;
}
.project-list {
  width: 100%;
  padding: 0 3.208rem;
  margin-top: 2.292rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
}
.list {
  width: 49%;
  height: auto;
  padding: 0.625rem 2.5rem 2.5rem 2.5rem;
  margin-bottom: 1.25rem;
  border-radius: 0.75rem;
  border-top: 0.167rem solid #a6c142;
  overflow: hidden;
  margin-right: 1.1%;
  background-color: #f2f2f2;
  transition: all 0.3s ease;
}
.full1{
  width: 100% !important;
  height: auto !important;
  margin-right: 0 !important;  
}
.full{
  width: 100% !important;
  margin-right: 0 !important;
}
.list p {
  color: #333;
  transition: all 0.3s ease;
}
.list:hover {
  background: #a6c142;
}
.list:hover p,
.list:hover .list-title,
.list:hover .list-main {
  color: #fff;
}
.list-main /deep/p{
  font-size: 0.667rem;
  line-height: 2 !important;
}
.list:hover .line {
  background-color: #fff;
}
.list:nth-child(even) {
  margin-right: 0;
}
.list-title {
  width: 100%;
  height: 3.75rem;
  font-size: 1.167rem;
  color: #27b2e1;
  line-height: 3.75rem;
  position: relative;
  border-bottom: solid 0.083rem #d9d9d9;
  transition: all 0.3s ease;
}
.line {
  position: absolute;
  left: 0;
  bottom: -0.083rem;
  width: 3.333rem;
  height: 0.083rem;
  background-color: #27b2e1;
  transition: all 0.3s ease;
}
.list .list-main {
  margin-top: 2.083rem;
  font-size: 0.667rem;
  line-height: 2;
  color: #000;
  transition: all 0.3s ease;
}
.mar{
  padding:  0 3.208rem !important;
}
.tableImage{
  width: 100%;
}
.tableImage /deep/p{
  font-size: 0.667rem;
  line-height: 2 !important;
}
.tableImage /deep/ img{
  width:49%;
  height: auto !important;
  max-height: 23.125rem;
  margin-right: 1.1%;
}
.tablePhoneImage /deep/ img{
  width:100% !important;
  height: auto !important;
  margin-right: 0 !important;
  font-size: 14px;
}
.tableImage /deep/ img:nth-child(2){
  margin-right: 0;
}
/* 样式覆盖 */
.nav-head >>> .el-breadcrumb {
  font-size: 0.583rem;
}
.nav-head >>> .el-menu.el-menu--horizontal {
  height: 6.67rem;
  border-bottom: solid 0 #e6e6e6;
}
.nav-head >>> .el-menu--horizontal > .el-menu-item {
  height: 6.67rem;
  line-height: 6.67rem;
  font-size: 0.917rem;
  text-align: center;
}
.nav-head >>> .el-breadcrumb__inner.is-link {
  color: #fff;
}
.nav-head >>> .el-breadcrumb__separator {
  color: #fff;
}
.nav-head >>> .el-breadcrumb__item .el-breadcrumb__inner {
  color: #fff;
}

.nav-head >>> .el-menu .el-menu-item:after {
  content: '';
  display: block;
  width: 0.042rem;
  height: 0.833rem;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  background-color: #fff;
}
.el-menu .el-menu-item:last-child:after {
  display: none;
}
.nav-head >>> .el-menu--horizontal .el-menu-item.is-active {
  border-bottom: 0.167rem solid #aac93b !important;
}
</style>