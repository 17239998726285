<template>
  <div>
    <div class="main">
      <div class="main-bg"></div>
      <div class="main-text">
        <p class="text-title" style="margin: 0.417rem 0">联系我们</p>
        <p class="text-title-en">CONTACT US</p>
      </div>
    </div>
    <div class="nav-head" v-if="!phoneSize">
      <div class="nav-title">
        <el-menu
          :default-active="changeNav"
          class="el-menu-demo"
          mode="horizontal"
          text-color="#fff"
          background-color="transparent"
          active-text-color="#fff"
        >
          <el-menu-item index="callUs" @click="toCallUs">联系方式</el-menu-item>
          <el-menu-item index="joinUs">加入我们</el-menu-item>
        </el-menu>
      </div>
      <div class="nav-text">
        <div style="margin-right: 0.625rem; width: 1.042rem; height: 1.042rem">
          <img src="@/assets/images/icon/icon-house.png" alt="" />
        </div>
        <div class="right-nav">
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>联系我们</el-breadcrumb-item>
            <el-breadcrumb-item>加入我们</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
      </div>
    </div>
    <div class="nav-two" v-if="phoneSize">
      <div class="two-wrap" @click="changeCate1()">
        <div class="two-text">联系方式</div>
      </div>
      <div class="two-wrap" @click="changeCate2()">
        <div class="two-text">加入我们</div>
      </div>
    </div>
    <!-- 项目 -->
    <div>
      <div style="margin: 0 auto; text-align: center">
        <p class="text-title2">加入我们</p>
        <p class="text-title2-en" style="color:#8A8A8A">JOIN US</p>
      </div>
      <div class="project">
        <!-- 锚点 -->
        <div id="fj" style="width:0.042rem;height:0.042rem"></div>
        <div class="project-main">
          <div
            class="wrap"
            :class="phoneSize ?'phone-wrap':''"
            @click="showJoinDetail(item.id)"
            v-for="item in jobList"
            :key="item.index"
          >
            <div class="wrap-text">
              <p  :class="phoneSize ?'size':''">{{ item.address }}</p>
              <p style="font-size: 0.833rem; margin-top: 0.625rem"  :class="phoneSize ?'size':''">{{ item.title }}</p>
            </div>
            <div class="wrap-img">
              <!-- <img src="@/assets/images/icon/right.png" alt="" /> -->
              <i class="el-icon-arrow-right" :class="phoneSize ?'mini-size':''"></i>
            </div>
          </div>
        </div>
        <div class="project-foot">
          <!-- <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pageData.pageCount"
            :page-size="pageData.pageSize"
            layout="prev, pager, next, jumper"
            :total="pageData.total"
          >
          </el-pagination> -->
        </div>
      </div>
    </div>
    <!-- 弹框 -->
    <div v-if="showDetail">
      <div class="mask" @click="showDetail = false"></div>
      <div class="pop" :class="phoneSize ?'phonePop':''">
        <div class="close" @click="showDetail = false">
          <img src="@/assets/images/us/close_03.jpg" alt="" />
        </div>
        <h2 class="blue" style="font-size: 1.042rem" v-if="!phoneSize">{{ jobDetail.title }}</h2>
        <h2 class="blue" style="font-size: 16px" v-if="phoneSize">{{ jobDetail.title }}</h2>
        <el-divider></el-divider>
        <div class="requirement">
          <h3 style="margin: 1.25rem 0 0; font-size: 15px">工作职责</h3>
          <div class="job-text" v-html="jobDetail.content1"></div>
        </div>
        <div class="requirement">
          <h3 style="margin: 1.25rem 0 0; font-size: 15px">任职要求</h3>
          <div class="job-text" v-html="jobDetail.content2"></div>
        </div>
        <div class="call blue">
          联系人：{{ jobDetail.contact_person }} 微信：{{ jobDetail.wechat }} 邮箱：{{
            jobDetail.email
          }}
        </div>
      </div>
    </div>
    <foot></foot>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  data () {
    return {
      showDetail: false,
      jobList: [], //工作列表
      jobDetail: {}, //工作详情
      pageData: {
        pageCount: 1, //当前页
        pageSize: 100, //条数
        total: 5, //总共条数
      }
    }
  },
  computed: {
    ...mapState(["phoneSize"]),
    changeNav () {
      const path = this.$route.path.replace('/', '')
      return path || '/'
    }
  },
  
  mounted () {
    this.queryList()
    this.toScroll()
  },
  methods: {
    changeCate1 () {
      this.$router.push('callUs')
    },
    changeCate2 () {
      this.$router.push('joinUs')
    },
    toCallUs () {
      this.$router.push('callUs')
    },
    // 条数
    handleSizeChange (val) {
      this.pageData.pageSize = val
      this.queryList()
    },
    // 页数
    handleCurrentChange (val) {
      this.pageData.pageCount = val
      this.queryList()
    },
    showJoinDetail (n) {
      this.showDetail = true
      // this.$axios.get('/api/job/detail?id=' + n).then((res) => {
        this.$axios.get('job/detail?id=' + n).then((res) => {
        this.jobDetail = res.data.data
      })
    },
    // 更新工作数据
    queryList () {
      // this.$axios.get('/api/job/index?page=' + this.pageData.pageCount + '&pagesize=' + this.pageData.pageSize).then((res) => {
        this.$axios.get('job/index?page=' + this.pageData.pageCount + '&pagesize=' + this.pageData.pageSize).then((res) => {
        this.jobList = res.data.data.list
        // this.pageData.total = res.data.data.total_count
      })
    },
    // 锚点
    toScroll(){
      this.$el.querySelector('#fj').scrollIntoView({behavior: "smooth",block: "center",});
    },
  }
}
</script>
<style>
.project-foot {
  width: 100%;
  margin: 4.167rem 0;
  text-align: center;
}
.el-pagination__jump {
  font-size: 0.5rem;
}
</style>
<style scoped>
  .size{
    font-size: 14px !important;
  }
  .mini-size{
    font-size: 12px !important;
  }
  .size /deep/p{
    font-size: 14px;
  }
.el-menu-item:hover{
  color: #fff !important;
}
.el-menu-item{
  color: rgba(255, 255, 255, 0.7) !important;
}
.text-title {
  font-size: 2.667rem;
  font-family: ssb;
}
.text-title-en {
  font-size: 1.5rem;
  font-family: Arial, Helvetica, sans-serif;
}
.text-title2 {
  font-size: 2.292rem;
  font-family: ssb;
}
.text-title2-en {
  font-size: 1.417rem;
  font-family: Arial, Helvetica, sans-serif;
}
img {
  width: 100%;
  height: 100%;
}
/* 走进汇君 */
.main {
  width: 100%;
  padding-top: 0.042rem;
  height: 36.667rem;
  overflow: hidden;
  position: relative;
}
.main-bg{
  position: absolute;
  width: 100%;
  height: 100%;
  background: url(@/assets/images/us/us_01.jpg) no-repeat center;
  background-size: cover;
  animation: zoom_big2 16s ease-out infinite;
  z-index: -1;
}
@keyframes zoom_big2 {
  0%{
    transform: scale(1);
  }
  60% {
    transform: scale(1.05);
  }
  95% {
    transform: scale(1);
  }
  100% {
    transform: scale(1);
  }
}
.main-text {
  margin: 15.417rem 0 0 3.333rem;
  color: #fff;
}

/* 分类导航 */
.nav-head {
  position: relative;
  margin-bottom: 9.167rem;
}
.nav-title {
  width: 54.167rem;
  height: 6.667rem;
  background-image: linear-gradient(to right, #72caed, #26b1e0);
  border-radius: 0 3.75rem 0 0;
  position: absolute;
  left: 0;
  top: -3.75rem;
  z-index: 11;
  padding: 0 0 0 4.167rem;
}
.nav-text {
  width: 41.667rem;
  height: 6.25rem;
  background-image: linear-gradient(to right, #e6eeca, #a6c142);
  border-radius: 0 0 0 2.083rem;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.right-nav {
  margin-right: 3.333rem;
}
/* 项目 */
.project {
  width: 100%;
  padding: 0 3.667rem;
  margin-top: 2.292rem;
}
.project-main {
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
}
.wrap {
  width: 32%;
  height: 8.958rem;
  border-left: solid 0.333rem #a6c142;
  border-radius: 0.333rem;
  margin-right: 1.6%;
  margin-bottom: 1.25rem;
  overflow: hidden;
  background: #f3f3f3;
  padding:0 2.083rem;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  transition: all 0.3s ease;
}
.phone-wrap{
  width: 100% !important;
  margin-right: 0;
}
.wrap:nth-child(3n) {
  margin-right: 0;
}
.wrap-text {
  width: 70%;
  margin: auto 0;
}
.wrap-text /deep/ p:first-child{
  font-size: 0.667rem;
  line-height: 2;
}
.wrap-text /deep/ p:last-child{
  font-size: 0.875rem;
  line-height: 2;
}
.wrap-img {
  width: 1.797rem;
  height: 1.797rem;
  background-color: #61AEDF;
  border-radius: 50%;
  margin: auto 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.el-icon-arrow-right{
  color: #fff;
  font-size: 1.9rem;
}
.wrap:hover{
  background-color: #0E4158;
  color: #fff !important;
}
.wrap:hover .wrap-img{
  background: #a6c142;
}
/* 样式覆盖 */
.nav-head >>> .el-breadcrumb {
  font-size: 0.583rem;
}
.nav-head >>> .el-menu.el-menu--horizontal {
  height: 6.67rem;
  border-bottom: solid 0 #e6e6e6;
}
.nav-head >>> .el-menu--horizontal > .el-menu-item {
  height: 6.67rem;
  line-height: 6.67rem;
  font-size: 0.917rem;
  text-align: center;
}
.nav-head >>> .el-breadcrumb__inner.is-link {
  color: #fff;
}
.nav-head >>> .el-breadcrumb__separator {
  color: #fff;
}
.nav-head >>> .el-breadcrumb__item .el-breadcrumb__inner {
  color: #fff;
}
/* 弹框 */
.mask {
  background-color: #000;
  opacity: 0.8;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
}
.pop {
  background-color: #fff;
  position: fixed;
  width: 70%;
  height: auto;
  border-radius: 1.25rem;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  z-index: 100;
  font-size: 0.583rem;
  padding: 3.75rem 3.75rem 1rem;
}
.phonePop{
  height: 44.445rem !important;
  overflow-y: scroll;
}
.close {
  position: absolute;
  top: 1.25rem;
  right: 1.25rem;
  width: 2.208rem;
  height: 2.208rem;
  cursor: pointer;
}
.blue {
  color: #29b2e0;
}
.requirement {
  width: 100%;
}
.job-text /deep/p{
  font-size: 14px;
  line-height: 2 !important;
}
.call {
  width: 100%;
  margin: 0.417rem 0;
  font-size: 13px;
}
.nav-head >>> .el-menu--horizontal .el-menu-item.is-active {
  border-bottom: 0.167rem solid #aac93b !important;
}
</style>