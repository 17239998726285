<template>
  <div>
    <div class="main">
      <div class="main-bg"></div>
      <div class="main-text">
        <p class="text-title" style="margin: 0.417rem 0">解决方案</p>
        <p class="text-title-en">SOLUTION</p>
      </div>
    </div>
    <div class="nav-head" v-if="!phoneSize">
      <div class="nav-title">
        <el-menu
          :default-active="activeIndex"
          class="el-menu-demo"
          mode="horizontal"
          text-color="#fff"
          background-color="transparent"
          active-text-color="#fff"
        >
          <el-menu-item index="1" @click="toScroll1()">市政厨余/餐厨垃圾处理解决方案</el-menu-item>
          <el-menu-item index="2" @click="toScroll2()">养殖企业环保解决方案</el-menu-item>
          <el-menu-item index="3" @click="toScroll3()">县域集中式有机废弃物解决方案</el-menu-item>
        </el-menu>
      </div>
      <div class="nav-text">
        <div style="margin-right: 0.625rem; width: 1.042rem; height: 1.042rem">
          <img src="@/assets/images/icon/icon-house.png" alt="" />
        </div>
        <div class="right-nav">
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>走进汇君</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
      </div>
    </div>
    <div class="nav-two" v-if="phoneSize">
      <div class="two-wrap">
        <div class="two-text" @click="toScroll1()">市政厨余/餐厨垃圾处理解决方案</div>
      </div>
      <div class="two-wrap">
        <div class="two-text" @click="toScroll2()">养殖企业环保解决方案</div>
      </div>
      <div class="two-wrap">
        <div class="two-text" @click="toScroll3()">县域集中式有机废弃物解决方案</div>
      </div>
    </div>
    <div 
    class="solution wrap" 
    :class="[barType(index)]"
    v-for="(item, index) in solution" 
    :key="index">
      <div class="solution-bg">
        <img :src="$pregImg + item.thumb" alt="" />
      </div>
      <div
        :class="[
          phoneSize ? 'phoneSolution' : 'solution-text',
          index % 2 == 0 ? 'left' : 'right',
          index % 2 == 0 ? 'fadeInRight' : 'fadeInLeft',
        ]"
        class=" wow"
        data-wow-duration="1s" 
        data-wow-delay="0.5s"  
        data-wow-iteration="1"
      >
        <div class="right-text"
        >
          <div style="font-size:1.25rem;font-weight:600; margin-bottom: 0.833rem" :class="phoneSize ?'size':''">
            {{ item.title }}
          </div>
          <div class="text" :class="phoneSize?'size':''" v-html="item.content"></div>
        </div>
      </div>
    </div>
    <foot></foot>
  </div>
</template>
<script>
import {WOW} from 'wowjs'
import { mapState } from "vuex";
export default {
  data () {
    return {
      activeIndex: '1',
      solution: [],
    }
  },
  computed: {
    ...mapState(["phoneSize"]),
  },
  mounted () {
    // this.$axios.get('/api/solution/index').then((res) => { // 获取首页数据
    this.$axios.get('solution/index').then((res) => { // 获取首页数据
      this.solution = res.data.data.list
    })
    setTimeout(()=>{
      const id = this.$route.query.id
      if(id == 1){
        this.toScroll1()
      }else if(id == 2){
        this.toScroll2()
      }else if(id == 3){
        this.toScroll3()
      }
    },200)
  },
  // updated(){
    
  // },
  watch: {
    solution() {
      this.$nextTick(() => {
        // 在dom渲染完后,再执行动画
        let wow = new WOW({
          boxClass: 'wow',    //需要执行动画的元素的 class
          animateClass: 'animated',        //animation.css 动画的 class
          offset: 0,            //距离可视区域多少开始执行动画
          mobile: true,        //是否在移动设备上执行动画
          live: true        //异步加载的内容是否有效
        });
        wow.init()
      });
    },
    '$route': function () {
      const id = this.$route.query.id
      if(id == 1){
        this.toScroll1()
      }else if(id == 2){
        this.toScroll2()
      }else if(id == 3){
        this.toScroll3()
      }
    }
  },
  methods:{
    // 动态添加类
    barType(index){
      const com = 'solution-'
      if(index>=0){
        return (`${com}`+index)
      }
    },
    // 锚点
    toScroll1(){
      this.$el.querySelector('.solution-0').scrollIntoView({behavior: "smooth",block: "center",});
    },
    toScroll2(){
      this.$el.querySelector('.solution-1').scrollIntoView({behavior: "smooth",block:"center"});
    },
    toScroll3(){
      this.$el.querySelector('.solution-2').scrollIntoView({behavior: "smooth",block: "center",});
    }
  }
}
</script>
<style scoped>
  .size{
    font-size: 15px !important;
  }
.el-menu-item:hover{
  color: #fff !important;
}
.el-menu-item{
  color: rgba(255, 255, 255, 0.7) !important;
}
.text-title {
  font-size: 2.667rem;
  font-family: ssb;
}
.text-title-en {
  font-size: 1.5rem;
  font-family: Arial, Helvetica, sans-serif;
}
img {
  width: 100%;
  height: 100%;
}
/* 走进汇君 */
.main {
  width: 100%;
  padding-top: 0.042rem;
  height: 36.667rem;
  overflow: hidden;
  position: relative;
}
.main-bg{
  position: absolute;
  width: 100%;
  height: 100%;
  background: url(@/assets/images/solution/solution-01.jpg) no-repeat center;
  background-size: cover;
  animation: zoom_big2 16s ease-out infinite;
  z-index: -1;
}
@keyframes zoom_big2 {
  0%{
    transform: scale(1);
  }
  60% {
    transform: scale(1.05);
  }
  95% {
    transform: scale(1);
  }
  100% {
    transform: scale(1);
  }
}
.main-text {
  margin: 15.417rem 0 0 3.333rem;
  color: #fff;
}

/* 分类导航 */
.nav-head {
  position: relative;
  margin-bottom: 9.167rem;
}
.nav-title {
  width: 54.167rem;
  height: 6.667rem;
  background-image: linear-gradient(to right, #72caed, #26b1e0);
  border-radius: 0 3.75rem 0 0;
  position: absolute;
  left: 0;
  top: -3.75rem;
  z-index: 11;
  padding: 0 0 0 4.167rem;
}
.nav-text {
  width: 41.667rem;
  height: 6.25rem;
  background-image: linear-gradient(to right, #e6eeca, #a6c142);
  border-radius: 0 0 0 2.083rem;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.right-nav {
  margin-right: 3.333rem;
}

/* 方案 */
.wrap {
  width: 100%;
  height: 37.917rem;
}
.solution {
  position: relative;
}
.solution-bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 37.917rem;
}
.solution-bg img {
  z-index: -8;
}
.solution-text {
  position: absolute;
  top: 10.833rem;
  width: 29.167rem;
  height: 19.375rem;
  background-color: rgb(243, 243, 243, 0.9);
}
.phoneSolution {
  position: absolute;
  top: 5.833rem;
  width: 70%;
  height: 25.375rem;
  background-color: rgb(243, 243, 243, 0.9);
}
.right-text {
  width: 100%;
  height: 19.375rem;
  padding-top: 3.167rem;
  padding-left: 3.542rem;
}
.left {
  border-left: solid 0.625rem #a6c142;
  right: 0;
}
.right {
  border-right: solid 0.625rem #a6c142;
  left: 0;
}
.text /deep/p {
  font-size: 0.667rem;
  line-height: 2 !important;
}
.size /deep/ p{
  font-size: 13px !important;
  line-height: 1.5 !important;
}
/* 样式覆盖 */
.nav-head >>> .el-breadcrumb {
  font-size: 0.583rem;
}
.nav-head >>> .el-menu.el-menu--horizontal {
  height: 6.67rem;
  border-bottom: solid 0 #e6e6e6;
}
.nav-head >>> .el-menu--horizontal > .el-menu-item {
  height: 6.67rem;
  line-height: 6.67rem;
  font-size: 0.917rem;
  text-align: center;
}
.nav-head >>> .el-breadcrumb__inner.is-link {
  color: #fff;
}
.nav-head >>> .el-breadcrumb__separator {
  color: #fff;
}
.nav-head >>> .el-breadcrumb__item .el-breadcrumb__inner {
  color: #fff;
}
.nav-head >>> .el-menu .el-menu-item:after {
  content: '';
  display: block;
  width: 0.042rem;
  height: 0.833rem;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  background-color: #fff;
}
.el-menu .el-menu-item:last-child:after {
  display: none;
}
.nav-head >>> .el-menu--horizontal .el-menu-item.is-active {
  border-bottom: 0.167rem solid #aac93b !important;
}
</style>