<template>
  <div>
    <div class="main">
      <div class="main-bg"></div>
      <div class="main-text">
        <p class="text-title" style="margin: 0.417rem 0">联系我们</p>
        <p class="text-title-en">CONTACT US</p>
      </div>
    </div>
    <div class="nav-head" v-if="!phoneSize">
      <div class="nav-title">
        <el-menu
          :default-active="changeNav"
          class="el-menu-demo"
          mode="horizontal"
          text-color="#fff"
          background-color="transparent"
          active-text-color="#fff"
        >
          <el-menu-item index="callUs">联系方式</el-menu-item>
          <el-menu-item index="joinUs" @click="toJoinUs">加入我们</el-menu-item>
        </el-menu>
      </div>
      <div class="nav-text">
        <div style="margin-right: 0.625rem; width: 1.042rem; height: 1.042rem">
          <img src="@/assets/images/icon/icon-house.png" alt="" />
        </div>
        <div class="right-nav">
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>联系我们</el-breadcrumb-item>
            <el-breadcrumb-item>联系方式</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
      </div>
    </div>
    <div class="nav-two" v-if="phoneSize">
      <div class="two-wrap" @click="changeCate1()">
        <div class="two-text">联系方式</div>
      </div>
      <div class="two-wrap" @click="changeCate2()">
        <div class="two-text">加入我们</div>
      </div>
    </div>
    <div :class="phoneSize ?'us-phone':'us'">
      <div class="us-l" :class="phoneSize ?'us-l-phone':''">
        <div class="image_01">
          <p class="text-title2">联系方式</p>
          <p class="text-title2-en" style="color:#8A8A8A" :class="phoneSize?'size':''">CONTACT</p>
        </div>
        <p class="call name" :class="phoneSize?'big-size':''">青岛汇君环境能源工程有限公司</p>
        <p class="call" :class="phoneSize?'size':''">公司地址:&nbsp;&nbsp;{{ usData.address }}</p>
        <p class="call" :class="phoneSize?'size':''">办公座机:&nbsp;&nbsp;{{ usData.tel }}</p>
        <p class="call" :class="phoneSize?'size':''">业务咨询:&nbsp;&nbsp;{{ usData.phone }}</p>
        <p class="call" :class="phoneSize?'size':''">企业邮箱:&nbsp;&nbsp;{{ usData.email }}</p>
        <div class="image_02"><img src="@/assets/images/wechat.jpg" alt="" /></div>
      </div>
      <div class="us-r" :class="phoneSize ?'us-r-phone':''" id="container"></div>
    </div>
    <foot></foot>
  </div>
</template>
<script>
import { mapState } from "vuex";
import AMapLoader from "@amap/amap-jsapi-loader";
export default {
  data () {
    return {
      usData: {},
      map: null
    };
  },
  computed: {
    ...mapState(["phoneSize"]),
    changeNav () {
      const path = this.$route.path.replace('/', '')
      return path || '/'
    }
  },
  mounted () {  
    // this.$axios.get('/api/contact/index').then((res) => {
      this.$axios.get('contact/index').then((res) => {
        console.log(res.data);
      this.usData = res.data.data
    }),
      //DOM初始化完成进行地图初始化
      this.initMap();
  },
  methods: {
    changeCate1 () {
      this.$router.push('callUs')
    },
    changeCate2 () {
      this.$router.push('joinUs')
    },
    toJoinUs () {
      this.$router.push('joinUs')
    },
    initMap () {
      AMapLoader.load({
        key: "ae848df445bf26c6026f1323a7442672",             // 申请好的Web端开发者Key，首次调用 load 时必填
        version: "2.0",      // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: [''],       // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      }).then((AMap) => {
        this.map = new AMap.Map("container", {  //设置地图容器id
          viewMode: "3D",    //是否为3D地图模式
          zoom: 14,           //初始化地图级别
          // center:[120.381347,36.090367], //初始化地图中心点位置
          center: [120.503422,36.171191], //初始化地图中心点位置
        });

        var marker = new AMap.Marker({
          // position: [120.379784, 36.092641],
          position: [120.503422,36.171191],
          icon: "https://huijun.net.cn/img/map.png", // Icon的图像，地址引入const
          // offset: new AMap.Pixel(-16, -16)
        })
        marker.setMap(this.map)
        // 缩放地图到合适的视野级别
        this.map.setFitView([marker])

      }).catch(e => {
        console.log(e);
      })
    },

    // 销毁地图容器
    destroyed () {
      this.map.destroy();
      this.map = null;
    }
  },
  beforeDestroy () {
    this.destroyed();
  },
}
</script>
<style>
.nav-head .el-breadcrumb__item .el-breadcrumb__inner{
  color: #fff !important;
}
</style>
<style scoped>
  .big-size{
    font-size: 16px !important;
  }
  .size{
    font-size: 14px !important;
  }
.el-menu-item:hover{
  color: #fff !important;
}
.el-menu-item{
  color: rgba(255, 255, 255, 0.7) !important;
}
.text-title {
  font-size: 2.667rem;
  font-family: ssb;
}
.text-title-en {
  font-size: 1.5rem;
  font-family: Arial, Helvetica, sans-serif;
}
.text-title2 {
  font-size: 2.292rem;
  font-family: ssb;
}
.text-title2-en {
  font-size: 1.417rem;
  font-family: arial;
}
img {
  width: 100%;
  height: 100%;
}
/* 走进汇君 */
.main {
  width: 100%;
  padding-top: 0.042rem;
  height: 36.667rem;
  overflow: hidden;
  position: relative;
}
.main-bg{
  position: absolute;
  width: 100%;
  height: 100%;
  background: url(@/assets/images/us/us_01.jpg) no-repeat center;
  background-size: cover;
  animation: zoom_big2 16s ease-out infinite;
  z-index: -1;
}
@keyframes zoom_big2 {
  0%{
    transform: scale(1);
  }
  60% {
    transform: scale(1.05);
  }
  95% {
    transform: scale(1);
  }
  100% {
    transform: scale(1);
  }
}
.main-text {
  margin: 15.417rem 0 0 3.333rem;
  color: #fff;
}

/* 分类导航 */
.nav-head {
  position: relative;
  margin-bottom: 9.167rem;
}
.nav-title {
  width: 54.167rem;
  height: 6.667rem;
  background-image: linear-gradient(to right, #72caed, #26b1e0);
  border-radius: 0 3.75rem 0 0;
  position: absolute;
  left: 0;
  top: -3.75rem;
  z-index: 11;
  padding: 0 0 0 4.167rem;
}
.nav-text {
  width: 41.667rem;
  height: 6.25rem;
  background-image: linear-gradient(to right, #e6eeca, #a6c142);
  border-radius: 0 0 0 2.083rem;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.right-nav {
  margin-right: 3.333rem;
}
/* 我们 */
.us {
  display: flex;
  margin-bottom: 0.417rem;
}
.us-phone{
  margin-bottom: 0.417rem;
}
.us-l,
.us-r {
  width: 50%;
  height: 34.167rem;
}
.us-l-phone{
  width: 100% !important;
  border-right: 0 solid #38b7e4 !important;
  height: auto;
}
.us-r-phone{
  width: 100% !important;
}
.us-l {
  padding: 4.583rem 0 1rem 4rem;
  border-right: 0.167rem solid #38b7e4;
}
.call {
  font-size: 0.75rem;
  line-height: 2;
}
.name{
  font-size: 1.167rem;
  line-height: 2;
  font-family: ssb;
}
.image_01 {
  width: 23.125rem;
  margin-bottom: 1.667rem;
}
.image_02 {
  width: 5.208rem;
  height: 5.208rem;
  margin-top: 0.417rem;
}
/* 样式覆盖 */
.nav-head >>> .el-breadcrumb {
  font-size: 0.583rem;
}
.nav-head >>> .el-menu.el-menu--horizontal {
  height: 6.67rem;
  border-bottom: solid 0 #e6e6e6;
}
.nav-head >>> .el-menu--horizontal .el-menu-item {
  height: 6.67rem;
  line-height: 6.67rem;
  font-size: 0.917rem;
  text-align: center;
}
.nav-head >>> .el-breadcrumb__inner.is-link {
  color: #fff;
}
.nav-head >>> .el-breadcrumb__separator {
  color: #fff;
}
.nav-head >>> .el-breadcrumb__item .el-breadcrumb__inner {
  color: #fff;
}
.nav-head >>> .el-menu--horizontal .el-menu-item.is-active {
  border-bottom: 0.167rem solid #aac93b !important;
}
</style>