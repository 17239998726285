var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._m(0),(!_vm.phoneSize)?_c('div',{staticClass:"nav-head"},[_c('div',{staticClass:"nav-title"},[_c('el-menu',{staticClass:"el-menu-demo",attrs:{"default-active":_vm.activeIndex,"mode":"horizontal","text-color":"#fff","background-color":"transparent","active-text-color":"#fff"}},[_c('el-menu-item',{attrs:{"index":"1"},on:{"click":function($event){return _vm.toScroll1()}}},[_vm._v("市政厨余/餐厨垃圾处理解决方案")]),_c('el-menu-item',{attrs:{"index":"2"},on:{"click":function($event){return _vm.toScroll2()}}},[_vm._v("养殖企业环保解决方案")]),_c('el-menu-item',{attrs:{"index":"3"},on:{"click":function($event){return _vm.toScroll3()}}},[_vm._v("县域集中式有机废弃物解决方案")])],1)],1),_c('div',{staticClass:"nav-text"},[_vm._m(1),_c('div',{staticClass:"right-nav"},[_c('el-breadcrumb',{attrs:{"separator-class":"el-icon-arrow-right"}},[_c('el-breadcrumb-item',{attrs:{"to":{ path: '/' }}},[_vm._v("首页")]),_c('el-breadcrumb-item',[_vm._v("走进汇君")])],1)],1)])]):_vm._e(),(_vm.phoneSize)?_c('div',{staticClass:"nav-two"},[_c('div',{staticClass:"two-wrap"},[_c('div',{staticClass:"two-text",on:{"click":function($event){return _vm.toScroll1()}}},[_vm._v("市政厨余/餐厨垃圾处理解决方案")])]),_c('div',{staticClass:"two-wrap"},[_c('div',{staticClass:"two-text",on:{"click":function($event){return _vm.toScroll2()}}},[_vm._v("养殖企业环保解决方案")])]),_c('div',{staticClass:"two-wrap"},[_c('div',{staticClass:"two-text",on:{"click":function($event){return _vm.toScroll3()}}},[_vm._v("县域集中式有机废弃物解决方案")])])]):_vm._e(),_vm._l((_vm.solution),function(item,index){return _c('div',{key:index,staticClass:"solution wrap",class:[_vm.barType(index)]},[_c('div',{staticClass:"solution-bg"},[_c('img',{attrs:{"src":_vm.$pregImg + item.thumb,"alt":""}})]),_c('div',{staticClass:"wow",class:[
        _vm.phoneSize ? 'phoneSolution' : 'solution-text',
        index % 2 == 0 ? 'left' : 'right',
        index % 2 == 0 ? 'fadeInRight' : 'fadeInLeft',
      ],attrs:{"data-wow-duration":"1s","data-wow-delay":"0.5s","data-wow-iteration":"1"}},[_c('div',{staticClass:"right-text"},[_c('div',{class:_vm.phoneSize ?'size':'',staticStyle:{"font-size":"1.25rem","font-weight":"600","margin-bottom":"0.833rem"}},[_vm._v(" "+_vm._s(item.title)+" ")]),_c('div',{staticClass:"text",class:_vm.phoneSize?'size':'',domProps:{"innerHTML":_vm._s(item.content)}})])])])}),_c('foot')],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main"},[_c('div',{staticClass:"main-bg"}),_c('div',{staticClass:"main-text"},[_c('p',{staticClass:"text-title",staticStyle:{"margin":"0.417rem 0"}},[_vm._v("解决方案")]),_c('p',{staticClass:"text-title-en"},[_vm._v("SOLUTION")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"margin-right":"0.625rem","width":"1.042rem","height":"1.042rem"}},[_c('img',{attrs:{"src":require("@/assets/images/icon/icon-house.png"),"alt":""}})])
}]

export { render, staticRenderFns }