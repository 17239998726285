<template>
  <div>
    <div class="main">
      <div class="main-bg"></div>
      <div class="main-text">
        <p class="text-title1" style="margin: 0.417rem 0">新闻中心</p>
        <p class="text-title1-en">NEWS CENTER</p>
      </div>
    </div>
    <div class="nav-head" v-if="!phoneSize">
      <div class="nav-title">
        <el-menu
          :default-active="activeIndex"
          class="el-menu-demo"
          mode="horizontal"
          text-color="#fff"
          background-color="transparent"
          active-text-color="#fff"
        >
          <el-menu-item
            :index="item.id"
            v-for="item in cateList"
            :key="item.id"
            @click="changeCate(item.id)"
          >
            {{ item.title }}
          </el-menu-item>
        </el-menu>
      </div>
      <div class="nav-text">
        <div style="margin-right: 0.625rem; width: 1.042rem; height: 1.042rem">
          <img src="@/assets/images/icon/icon-house.png" alt="" />
        </div>
        <div class="right-nav">
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>新闻中心</el-breadcrumb-item>
            <el-breadcrumb-item v-if="cateId == 1">公司新闻</el-breadcrumb-item>
            <el-breadcrumb-item v-else>行业状态</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
      </div>
    </div>
    <div class="nav-two" v-if="phoneSize">
      <div
        class="two-wrap"
        v-for="item in cateList"
        :key="item.id"
        @click="changeCate(item.id)"
      >
        <div class="two-text">{{ item.title }}</div>
      </div>
    </div>
    <!-- 项目 -->
    <div>
      <div style="margin: 0 auto; text-align: center" v-if="cateId == 1">
        <p class="text-title2">新闻中心</p>
        <p class="text-title2-en" style="color: #b8b8b8" :class="phoneSize?'size':''">NEWS CERTER</p>
      </div>
      <div style="margin: 0 auto; text-align: center" v-else>
        <p class="text-title2">行业状态</p>
        <p class="text-title2-en" style="color: #b8b8b8" :class="phoneSize?'size':''">INDUSTRY STATUS</p>
      </div>
      <div class="project">
        <!-- 锚点 -->
        <div id="fj" style="width:0.042rem;height:0.042rem"></div>
        <div class="project-main">
          <div class="wrap" :class="phoneSize ?'phone-wrap':''" v-for="(item, index) in newsList" :key="index">
            <div @click="toNewsDetail(item.id)">
              <div class="image"  :class="phoneSize ?'phone-image':''"><img :src="$pregImg + item.thumb" alt="" /></div>
              <div class="news-title" :class="phoneSize?'size':''">
                {{ item.title }}
              </div>
              <el-divider></el-divider>
              <div class="news-text" :class="phoneSize?'size':''">{{ item.description }}</div>
            </div>
          </div>
        </div>
        <div class="project-foot">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pageData.pageCount"
            :page-size="pageData.pageSize"
            layout="prev, pager, next, jumper"
            :total="pageData.total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <foot></foot>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  data () {
    return {
      activeIndex: '1',
      cateId: 1, // 标题ID
      cateList: [], //标题列表
      newsList: [], //新闻列表
      pageData: {
        pageCount: 1, //当前页
        pageSize: 9, //条数
        total: 9, //总共条数
      }
    };
  },
  watch: {
    '$route': function () {
      const id = this.$route.query.id // 标题id
      this.activeIndex = id
      this.cateId = id
      this.queryList()
      if(id != 1){
        this.toScroll()
      }
    }
  },
  mounted () {
    const id = this.$route.query.id // 标题id
    this.activeIndex = id
    this.cateId = id
    this.queryList()
    // this.$axios.get('/api/news/category').then((res) => {
      this.$axios.get('news/category').then((res) => {
      this.cateList = res.data.data.list
    })
    if(id != 1){
      this.toScroll()
    }
  },
  computed: {
    ...mapState(["phoneSize"]),
  },
  methods: {
    enter (i) {
      this.i = i
    },
    // 去往详情
    toNewsDetail (id) {
      var cateId = this.cateId
      this.$router.push({ path: 'newsDetail', query: { id: id ,cateId:cateId} })
    },
    changeCate (n) {
      this.cateId = n
      this.queryList()
    },
    // 条数
    handleSizeChange (val) {
      this.pageData.pageSize = val
      this.queryList()
    },
    // 页数
    handleCurrentChange (val) {
      this.pageData.pageCount = val
      this.queryList()
    },
    // 更新工作数据
    queryList () {
      // this.$axios.get('/api/news/index?cate_id=' + this.cateId + '&page=' + this.pageData.pageCount + '&pagesize=' + this.pageData.pageSize).then((res) => {
        this.$axios.get('news/index?cate_id=' + this.cateId + '&page=' + this.pageData.pageCount + '&pagesize=' + this.pageData.pageSize).then((res) => {
        this.newsList = res.data.data.list
        this.pageData.total = res.data.data.total_count
      })
    },
    // 锚点
    toScroll(){
      this.$el.querySelector('#fj').scrollIntoView({behavior: "smooth",block: "center",});
    },
  }
}
</script>
<style scoped>
  .size{
    font-size: 14px !important;
  }
.el-menu-item:hover{
  color: #fff !important;
}
.el-menu-item{
  color: rgba(255, 255, 255, 0.7) !important;
}
.text-title1 {
  font-size: 2.667rem;
  font-family: ssb;
}
.text-title1-en {
  font-size: 1.5rem;
  font-family: Arial, Helvetica, sans-serif;
}
.text-title2 {
  font-size: 2.292rem;
  font-family: ssb;
}
.text-title2-en {
  font-size: 1.417rem;
  font-family: arial;
}
img {
  width: 100%;
  height: 100%;
}
/* 走进汇君 */
.main {
  width: 100%;
  padding-top: 0.042rem;
  height: 36.667rem;
  overflow: hidden;
  position: relative;
}
.main-bg{
  position: absolute;
  width: 100%;
  height: 100%;
  background: url(@/assets/images/news/newBg_01.jpg) no-repeat center;
  background-size: cover;
  animation: zoom_big2 16s ease-out infinite;
  z-index: -1;
}
@keyframes zoom_big2 {
  0%{
    transform: scale(1);
  }
  60% {
    transform: scale(1.05);
  }
  95% {
    transform: scale(1);
  }
  100% {
    transform: scale(1);
  }
}
.main-text {
  margin: 15.417rem 0 0 3.333rem;
  color: #fff;
}

/* 分类导航 */
.nav-head {
  position: relative;
  margin-bottom: 9.167rem;
}
.nav-title {
  width: 54.167rem;
  height: 6.667rem;
  background-image: linear-gradient(to right, #72caed, #26b1e0);
  border-radius: 0 3.75rem 0 0;
  position: absolute;
  left: 0;
  top: -3.75rem;
  z-index: 11;
  padding: 0 0 0 4.167rem;
}
.nav-text {
  width: 41.667rem;
  height: 6.25rem;
  background-image: linear-gradient(to right, #e6eeca, #a6c142);
  border-radius: 0 0 0 2.083rem;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.right-nav {
  margin-right: 3.333rem;
}
/* 项目 */
.project {
  width: 100%;
  padding: 0 3.667rem;
  margin-top: 2.292rem;
}
.project-main {
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
}
.wrap {
  width: 32%;
  height: auto;
  margin-bottom: 1.25rem;
  margin-right: 1.6%;
}
.phone-wrap{
  width: 100% !important;
  margin-right: 0;
}
.wrap:nth-child(3n) {
  margin-right: 0;
}
.image {
  width: 100%;
  height: 12.917rem;
  overflow: hidden;
}
.phone-image{
  height: auto !important;
}
.image img {
  transition: all 0.6s ease;
}
.wrap:hover img {
  transform: scale(1.05);
}
.wrap:hover .news-title {
  color: #28b2e1;
}
.wrap .news-title {
  width: 100%;
  margin: 1.25rem 0.417rem;
  font-size: 0.667rem;
  font-weight: 550;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.news-text {
  margin: 1.25rem 0.417rem;
  font-size: 0.5rem;
  line-height: 1.5;
  color: #737373;
  white-space: nowrap; /*溢出不换行*/
  overflow: hidden; /*溢出部分隐藏*/
  text-overflow: ellipsis;
}
.project-foot {
  width: 100%;
  margin: 4.167rem 0;
}
/* 样式覆盖 */
.nav-head >>> .el-breadcrumb {
  font-size: 0.583rem;
}
.nav-head >>> .el-menu.el-menu--horizontal {
  height: 6.67rem;
  border-bottom: solid 0 #e6e6e6;
}
.nav-head >>> .el-menu--horizontal > .el-menu-item {
  height: 6.67rem;
  line-height: 6.67rem;
  font-size: 0.917rem;
  text-align: center;
  position: relative;
}
.nav-head >>> .el-menu .el-menu-item:after {
  content: '';
  display: block;
  width: 0.042rem;
  height: 0.833rem;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  background-color: #fff;
}
.el-menu .el-menu-item:last-child:after {
  display: none;
}
.nav-head >>> .el-breadcrumb__inner.is-link {
  color: #fff;
}
.nav-head >>> .el-breadcrumb__separator {
  color: #fff;
}
.nav-head >>> .el-breadcrumb__item .el-breadcrumb__inner {
  color: #fff;
}
.nav-head >>> .el-menu--horizontal .el-menu-item.is-active {
  border-bottom: 0.167rem solid #aac93b !important;
}
</style>